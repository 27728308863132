import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DialogContent from "@mui/material/DialogContent";
import {
  Checkbox,
  DialogActions,
  Grid,
  ListItemText,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useMutation } from "react-query";
import { MenuItem } from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { queryClient } from "App";
import CommonDialog from "../CommonDialog";
import TextFieldLabel from "../../TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { Distrct, States, boards } from "app/config/administrativeConstants";
import { updatePic, updateSchoolProfile } from "app/services/management";
import useFolderWiseFileUploader from "app/hooks/upload/useFolderWiseFileUploader";
const validationSchema = yup.object({
  schoolInitials: yup.string().required("School Initial Required"),
  schoolName: yup.string().required("School name Required"),
  address: yup.string().required("School address Required"),
  dist: yup.string().required("district Required"),
  state: yup.string().required("state Required"),
  affiliatedTo: yup.string().required("affialted to  Required"),
  otherAffiliatedTo: yup.string().when("affiliatedTo", {
    is: "Other",
    then: yup.string().required("Other affiliatedTo Required"),
    otherwise: yup.string(),
  }),

  educationType: yup.string().required("education type  Required"),
});
const UpdateSchoolProfileDiaolog = ({
  open = false,
  onClose = () => {},
  data = {},
}) => {
  const [stateIndex, setStateIndex] = useState(0);
  const [schoolMedium, setmedium] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      schoolLogo: "",
      coverPhoto: "",
      schoolInitials: "",
      schoolName: "",
      address: "",
      dist: "",
      pinCode: "",
      state: "",
      affiliatedTo: "",
      otherAffiliatedTo: "",
      affiliationNumber: "",
      educationType: "",
      schoolRegisterNumber: "",
      schoolRegisterDate: "",
      schoolEmail: "",
      schoolWebsite: "",
      schoolContactNumber: "",
      schoolGstNumber: "",
      licenseNumber: "",
      licenseDate: "",
      affiliationUpTo: "",
      medium: [],
      mediumList: ["Hindi", "English"],
      verifiedOn: "",
      signatureForIdCard: "",
      establishmentDate: "",
      udiseCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.medium?.length === 0) {
        toast.warning("Medium is required");
        return;
      }
      const updateProfileFirstPayload = {
        schoolLogo: values.schoolLogo,
        schoolName: values.schoolName,
        // schoolInitials: values.schoolInitials,
        schoolAddress: {
          address: values.address,
          dist: values.dist,
          pinCode: values.pinCode,
          state: values.state,
        },
        affiliatedTo: values.affiliatedTo,
        affiliationNumber: values.affiliationNumber,
        medium: schoolMedium,
        educationType: values.educationType,
        schoolRegisterNumber: values.schoolRegisterNumber,
        schoolRegisterDate: values.schoolRegisterDate,
        mobileNumber: String(values.mobileNumber),
        schoolContactNumber: String(values.schoolContactNumber),
        website: values?.schoolWebsite,
        email: values.schoolEmail,
      };
      const updateProfilePayload = {
        schoolLogo: values.schoolLogo,
        schoolName: values.schoolName,
        schoolAddress: {
          address: values.address,
          dist: values.dist,
          pinCode: values.pinCode,
          state: values.state,
        },
        affiliatedTo:
          values.affiliatedTo === "Other"
            ? values.otherAffiliatedTo
            : values.affiliatedTo,
        affiliationNumber: values.affiliationNumber,
        educationType: values.educationType,
        schoolRegisterNumber: values.schoolRegisterNumber,
        schoolRegisterDate: values.schoolRegisterDate,
        mobileNumber: String(values.mobileNumber),
        schoolContactNumber: String(values.schoolContactNumber),
        website: values?.schoolWebsite,
        affiliationUpTo: values?.affiliationUpTo,
        email: values.schoolEmail,
        udiseCode: values.udiseCode,
      };
      mutate(updateProfilePayload);
    },
    enableReinitialize: true,
  });

  const handleClose = () => {
    onClose();
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setmedium(typeof value === "string" ? value.split(",") : value);
  };
  const handleClickMedium = (item) => {
    formik.setValues((prev) => {
      const currentMedium = prev?.medium || []; // Ensuring it's an array
      if (currentMedium?.includes(item)) {
        return {
          ...prev,
          medium: currentMedium?.filter((res) => res !== item),
        };
      } else {
        return { ...prev, medium: [...currentMedium, item] };
      }
    });
  };

  const { handleUploadImage } = useFolderWiseFileUploader();
  const handleUploadFile = async (attachmentFile, uploadTarget) => {
    setLoading(true);

    let attachmentUrl = await handleUploadImage(
      true,
      attachmentFile,
      "withoutSession",
      "school",
      "schoolProfile"
    );
    if (uploadTarget === "cover") {
      formik.setValues((prev) => ({
        ...prev,
        coverPhoto: attachmentUrl.fileURL,
      }));
      uploadPicture({ coverPhoto: attachmentUrl?.fileURL });
    }
    if (uploadTarget === "profile") {
      formik.setValues((prev) => ({
        ...prev,
        schoolLogo: attachmentUrl.fileURL,
      }));
      uploadPicture({ schoolLogo: attachmentUrl?.fileURL });
    }
    setLoading(false);
  };

  const { mutate: uploadPicture, isLoading: isLoadingPic } = useMutation(
    updatePic,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        queryClient.invalidateQueries({ queryKey: ["getSchoolProfile"] });
      },
      onError: (error) => {
        toast.error(error.response.data?.message);
      },
    }
  );
  const { mutate, isLoading: updatingSchoolProfile } = useMutation(
    updateSchoolProfile,
    {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        queryClient.invalidateQueries({ queryKey: ["getSchoolProfile"] });
        handleClose();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  useEffect(() => {
    if (open) {
      formik.setValues((prev) => ({
        ...prev,
        schoolLogo: data?.schoolLogo,
        coverPhoto: data?.coverPhoto,
        schoolInitials: data?.schoolInitials,
        schoolName: data?.schoolName,
        address: data?.schoolAddress?.address,
        dist: data?.schoolAddress?.dist,
        pinCode: data?.schoolAddress?.pinCode,
        state: data?.schoolAddress?.state,
        affiliatedTo: data?.affiliatedTo,
        affiliationNumber: data?.affiliationNumber,
        educationType: data?.educationType,
        schoolRegisterNumber: data?.schoolRegisterNumber,
        schoolRegisterDate: data?.schoolRegisterDate,
        schoolEmail: data?.email,
        schoolWebsite: data?.website,
        schoolContactNumber: data?.schoolContactNumber,
        mobileNumber: data?.mobileNumber,
        schoolGstNumber: data?.gstNumber,
        licenseNumber: data?.ekalRefNumber,
        licenseDate: data?.verifiedOn,
        medium: data?.medium,
        verifiedOn: data?.verifiedOn,
        affiliationUpTo: data?.affiliationUpTo,
        signatureForIdCard: data?.signatureForIdCard,
      }));
    }
  }, [open]);

  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        title="Edit School Profile"
        minWidth="600px"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit} id="update-school-profile-form">
            <UploadedCoverPhoto
              src={
                formik.values.coverPhoto
                  ? formik.values.coverPhoto
                  : "/banner-placeholder.png"
              }
            />
            <LoadingButton
              loading={isLoadingPic || loading}
              variant="outlined"
              color="secondary"
              sx={{ borderRadius: "10px" }}
              component="label"
            >
              <input
                hidden
                type="file"
                onChange={(e) => handleUploadFile(e.target.files[0], "cover")}
                accept="image/*"
              />
              Upload Cover Photo
            </LoadingButton>

            <div className="d-flex flex-row justify-content-start align-items-center">
              <ProfilePic
                src={
                  formik.values.schoolLogo
                    ? formik.values.schoolLogo
                    : "/image-placeholder.jpeg"
                }
              />
              <LoadingButton
                variant="outlined"
                color="secondary"
                sx={{ borderRadius: "10px" }}
                component="label"
                loading={isLoadingPic || loading}
              >
                <input
                  hidden
                  type="file"
                  onChange={(e) =>
                    handleUploadFile(e.target.files[0], "profile")
                  }
                  accept="image/*"
                />
                Upload Logo
              </LoadingButton>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextFieldLabel title="School Initials" />
                {data?.isProfileUpdated ? (
                  <CustomTextFieldDisabled
                    size="small"
                    fullWidth
                    value={formik.values.schoolInitials}
                    contentEditable={false}
                  />
                ) : (
                  <CustomTextField
                    size="small"
                    fullWidth
                    id="schoolInitials"
                    name="schoolInitials"
                    placeholder="type school initial"
                    value={formik.values.schoolInitials}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.schoolInitials &&
                      Boolean(formik.errors.schoolInitials)
                    }
                    helperText={
                      formik.touched.schoolInitials &&
                      formik.errors.schoolInitials
                    }
                  />
                )}
              </Grid>
              <Grid item xs={8}>
                <TextFieldLabel title="School Name" required />
                <CustomTextField
                  id="schoolName"
                  name="schoolName"
                  size="small"
                  placeholder="type school name"
                  fullWidth
                  value={formik.values.schoolName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.schoolName &&
                    Boolean(formik.errors.schoolName)
                  }
                  helperText={
                    formik.touched.schoolName && formik.errors.schoolName
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldLabel title="School Address" required />
                <CustomTextField
                  size="small"
                  fullWidth
                  id="address"
                  name="address"
                  placeholder="type school address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldLabel title="State" />
                <CustomSelectBox
                  displayEmpty
                  fullWidth
                  size="small"
                  id="state"
                  required
                  value={formik.values.state}
                  name="state"
                  onChange={formik.handleChange}
                  MenuProps={MenuProps}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {States.map((state, index) => (
                    <MenuItem
                      key={state}
                      value={state}
                      onClick={() => {
                        setStateIndex(index);
                      }}
                    >
                      {state}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
              </Grid>
              <Grid item xs={4}>
                <TextFieldLabel title="District" />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  id="dist"
                  required
                  value={formik.values.dist}
                  name="dist"
                  onChange={formik.handleChange}
                  MenuProps={MenuProps}
                  error={formik.touched.dist && Boolean(formik.errors.dist)}
                  helperText={formik.touched.dist && formik.errors.dist}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {Distrct[States.indexOf(String(formik.values.state)) + 1].map(
                    (district) => (
                      <MenuItem key={district} value={district}>
                        {district}
                      </MenuItem>
                    )
                  )}
                </CustomSelectBox>
              </Grid>
              <Grid item xs={4}>
                <TextFieldLabel title="Pincode" />
                <CustomTextField
                  fullWidth
                  placeholder="type pin code"
                  size="small"
                  id="pinCode"
                  name="pinCode"
                  value={formik.values.pinCode}
                  onChange={formik.handleChange}
                  inputProps={{
                    maxLength: 6,
                  }}
                  error={
                    formik.touched.pinCode && Boolean(formik.errors.pinCode)
                  }
                  helperText={formik.touched.pinCode && formik.errors.pinCode}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldLabel title="Affiliated By" required />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  id="affiliatedTo"
                  value={formik.values.affiliatedTo}
                  name="affiliatedTo"
                  onChange={formik.handleChange}
                  MenuProps={MenuProps}
                  error={
                    formik.touched.affiliatedTo &&
                    Boolean(formik.errors.affiliatedTo)
                  }
                  helperText={
                    formik.touched.affiliatedTo && formik.errors.affiliatedTo
                  }
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  <MenuItem value="none">None</MenuItem>
                  {boards.map((board, idx) => (
                    <MenuItem key={idx} value={board}>
                      {board}
                    </MenuItem>
                  ))}
                  <MenuItem value="Other">Other</MenuItem>
                </CustomSelectBox>
              </Grid>
              {formik?.values.affiliatedTo === "Other" && (
                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title="Other Affiliated By" />
                  <CustomTextField
                    size="small"
                    fullWidth
                    name="otherAffiliatedTo"
                    value={formik?.values.otherAffiliatedTo}
                    onChange={formik.handleChange}
                    placeholder="type affiliated by"
                    error={
                      Boolean(formik?.errors.otherAffiliatedTo) &&
                      formik?.touched.otherAffiliatedTo
                    }
                    helperText={
                      formik?.touched.otherAffiliatedTo &&
                      formik?.errors.otherAffiliatedTo
                    }
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <TextFieldLabel title="Affiliation Upto" />
                <DesktopDatePicker
                  inputFormat="DD MMM, YYYY"
                  onChange={(e) =>
                    formik.setValues((prev) => ({
                      ...prev,
                      affiliationUpTo: e,
                    }))
                  }
                  value={formik.values.affiliationUpTo}
                  renderInput={(params) => (
                    <CustomTextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Affiliation No" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="Type affiliation Number"
                  displayEmpt
                  id="affiliationNumber"
                  value={formik.values.affiliationNumber}
                  name="affiliationNumber"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.affiliationNumber &&
                    Boolean(formik.errors.affiliationNumber)
                  }
                  helperText={
                    formik.touched.affiliationNumber &&
                    formik.errors.affiliationNumber
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Medium" />
                {data?.obj?.isProfileUpdated ? (
                  <CustomTextFieldDisabled
                    fullWidth
                    size="small"
                    value={formik.values.medium?.join(", ")}
                  />
                ) : (
                  <CustomSelectBox
                    displayEmpty
                    fullWidth
                    size="small"
                    multiple
                    value={schoolMedium}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    renderValue={() => (
                      <Typography>
                        {formik.values?.medium?.join(", ")}
                      </Typography>
                    )}
                  >
                    {formik.values?.mediumList?.map((medium) => (
                      <MenuItem
                        key={medium}
                        value={medium}
                        onClick={() => handleClickMedium(medium)}
                      >
                        <Checkbox
                          checked={formik.values.medium?.includes(medium)}
                        />
                        <ListItemText primary={medium} />
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Education Type" required />
                <CustomSelectBox
                  displayEmpty
                  fullWidth
                  size="small"
                  id="educationType"
                  required
                  value={formik.values.educationType}
                  name="educationType"
                  onChange={formik.handleChange}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  <MenuItem value={"Co-Ed"}>Co-Ed</MenuItem>
                  <MenuItem value={"Only Boys"}>Only Boys</MenuItem>
                  <MenuItem value={"Only Girls"}>Only Girls</MenuItem>
                </CustomSelectBox>
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="School Registration Number" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="type school registration number"
                  id="schoolRegisterNumber"
                  name="schoolRegisterNumber"
                  value={formik.values.schoolRegisterNumber}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="School Registration Date" />

                <DesktopDatePicker
                  inputFormat="DD MMM YYYY"
                  onChange={(e) =>
                    formik.setValues((prev) => ({
                      ...prev,
                      schoolRegisterDate: e,
                    }))
                  }
                  value={formik.values.schoolRegisterDate}
                  renderInput={(params) => (
                    <CustomTextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="School Email" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="type school email"
                  id="schoolEmail"
                  name="schoolEmail"
                  value={formik.values.schoolEmail}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="School Website" />
                <CustomTextField
                  placeholder="type school website"
                  size="small"
                  fullWidth
                  id="schoolWebsite"
                  name="schoolWebsite"
                  value={formik.values.schoolWebsite}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="School Contact Number" />
                <CustomTextField
                  size="small"
                  placeholder="type school contact"
                  fullWidth
                  id="schoolContactNumber"
                  name="schoolContactNumber"
                  // type="number"
                  value={formik.values.schoolContactNumber}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="School Mobile Number" />
                <CustomTextField
                  placeholder="type school mobile Number"
                  size="small"
                  fullWidth
                  id="mobileNumber"
                  name="mobileNumber"
                  type="number"
                  value={formik.values.mobileNumber}
                  onChange={(e) => {
                    if (String(e.target.value).length < 11) {
                      formik.handleChange(e);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="School GST Number" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="type school gst"
                  id="schoolGstNumber"
                  name="schoolGstNumber"
                  value={formik.values.schoolGstNumber}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Establishment Date" />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    inputFormat="DD MMM, YYYY"
                    onChange={(e) =>
                      formik.setValues((prev) => ({
                        ...prev,
                        establishmentDate: e,
                      }))
                    }
                    value={formik.values.establishmentDate}
                    renderInput={(params) => (
                      <CustomTextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="License Number" />
                <CustomTextFieldDisabled
                  size="small"
                  fullWidth
                  value={formik.values.licenseNumber}
                  contentEditable={false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="License  Date" />
                <CustomTextFieldDisabled
                  size="small"
                  fullWidth
                  value={
                    formik.values.licenseDate && dayjs().format("DD MMM YYYY")
                  }
                  contentEditable={false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="UDISE Code" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="type UDISE code"
                  id="udiseCode"
                  name="udiseCode"
                  value={formik.values.udiseCode}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={updatingSchoolProfile}
            color="secondary"
            variant="contained"
            type="submit"
            form="update-school-profile-form"
            size="large"
            sx={{ fontSize: "capitalize" }}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default UpdateSchoolProfileDiaolog;
const ProfilePic = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 98px;
  border-radius: 20px;
  margin: 10px;
`;

const UploadCoverPicBtn = styled.div`
  background: #ffffff;
  border: 1px solid #3d3efd;
  border-radius: 20px;
  width: 13rem;
  display: flex;
  height: 3rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  p {
    padding-top: 12px;
    font-weight: 500;
    font-size: 11px;
    color: #3d3efd;
  }
`;

const UploadProfilePicBtn = styled.div`
  background: #ffffff;
  border: 1px solid #3d3efd;
  border-radius: 20px;
  width: 10rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    padding-top: 12px;
    font-weight: 500;
    font-size: 11px;
    color: #3d3efd;
  }
`;
const UploadedCoverPhoto = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 142px;
  border-radius: 20px;
  margin-bottom: 10px;
`;
