import { Box, Stack, Typography } from "@mui/material";
import CustomLoader from "app/components/common/CustomLoader";
import AddBookGroupDialog from "app/components/common/Dialogs/ledger/booksGroup/dialog/AddBookGroupDialog";
import EditBookGroupNameDialog from "app/components/common/Dialogs/ledger/booksGroup/dialog/EditBookGroupNameDialog";
import TableViewOfBookGroup from "app/components/common/Dialogs/ledger/booksGroup/dialog/TableViewOfBookGroup";
import TableActionButtons from "app/components/common/TableActionButtons";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import CommonTable1 from "app/components/Tables/CommonTable1";
import {
  selectActiveSession,
  selectedMediumSelector,
  selectSelectedSchoolId,
} from "app/modules/schools/selectors";
import {
  deleteBookGroup,
  getAggregatedBookGroup,
} from "app/services/schoolService/ledgerNaccounts.service";
import { getClassDropdown } from "app/services/student-management";
import React from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const BookGroup = () => {
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const selectedMedium = useSelector(selectedMediumSelector);
  const selectedSession = useSelector(selectActiveSession);

  const [state, setState] = React.useState({
    addBookGroupDialog: false,
    viewBookGroupDialog: false,
    editBookGroupDialog: false,
    deleteBookGroupDialog: false,
    selectedBookGroup: {},
    tableData: [],
    classData: [],
    addBookGroupClassDocId: "",
  });

  const columns = [
    {
      header: "Sr.No",
      Cell: ({
        cell: {
          row: { id },
        },
      }) => (
        <Typography>
          {parseInt(id) + 1 < 10 ? `0${parseInt(id) + 1}` : parseInt(id) + 1}
        </Typography>
      ),
      maxSize: 30,
    },
    {
      header: "Class-Stream",
      accessorKey: "className",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>
            {original?.className ?? "--"}{" "}
            {original?.stream ? `(${original?.stream})` : ""}
          </Typography>
        );
      },
      maxSize: 100,
    },
    {
      header: "Book Group /  Set Name",
      accessorKey: "bookGroups",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            {original?.bookGroups?.map((item, index) => (
              <Stack
                key={index}
                direction="row"
                gap={3}
                alignItems={"center"}
                sx={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "5px",
                  padding: "5px",
                  margin: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <Stack direction="row" gap={1}>
                  <Typography
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        selectedBookGroup: item,
                        viewBookGroupDialog: true,
                      }));
                    }}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#2789FD",
                    }}
                  >
                    {item?.bookGroupName ?? "--"}
                  </Typography>
                  <Typography>
                    ({item?.bookForSaleDocIdList?.length}{" "}
                    {item?.bookForSaleDocIdList?.length > 1 ? "Books" : "Book"})
                  </Typography>
                </Stack>

                <TableActionButtons
                  showDelete={true}
                  showEdit={true}
                  handleEdit={() => {
                    setState((prev) => ({
                      ...prev,
                      selectedBookGroup: item,
                      editBookGroupDialog: true,
                    }));
                  }}
                  handleDelete={() => {
                    setState((prev) => ({
                      ...prev,
                      selectedBookGroup: item,
                      deleteBookGroupDialog: true,
                    }));
                  }}
                />
              </Stack>
            ))}
            <Typography
              color="secondary"
              sx={{
                mt: 1,
                textTransform: "capitalize",
                fontSize: "12px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  addBookGroupClassDocId: original?.classDocId,
                }))
              }
            >
              + Add Book Group / Set Name
            </Typography>
          </>
        );
      },
    },
  ];

  const {
    refetch: getAggregatedBookGroupRefetch,
    isLoading: getAggregatedBookGroupLoading,
    isFetching: getAggregatedBookGroupFetching,
  } = useQuery({
    queryKey: ["getAggregatedBookGroup", selectedSession],
    queryFn: () =>
      getAggregatedBookGroup({
        session: selectedSession,
      }),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, tableData: res?.data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { isLoading: getClassDropdownDataLoading } = useQuery({
    queryKey: "getClassess",
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, classData: res?.data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: deleteBookGroupMutate, isLoading: deleteBookGroupLoading } =
    useMutation("deleteBookGroup", deleteBookGroup, {
      onSuccess: (success) => {
        setState((prev) => ({
          ...prev,
          deleteBookGroupDialog: false,
        }));
        getAggregatedBookGroupRefetch();
        toast.success(
          success?.data?.message ?? "Book Group Deleted Successfully"
        );
      },
      onError: (err) => {
        toast.error(
          err?.response?.data?.message ?? "Failed to delete Book Group"
        );
      },
    });

  return (
    <>
      <Helmet>
        <title>Books | Books Group</title>
      </Helmet>
      <CustomLoader
        show={
          getAggregatedBookGroupLoading ||
          getAggregatedBookGroupFetching ||
          getClassDropdownDataLoading
        }
      />

      <TableViewOfBookGroup
        open={state.viewBookGroupDialog}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            viewBookGroupDialog: false,
          }))
        }
        selectedBookGroupData={state.selectedBookGroup}
        refetch={() => getAggregatedBookGroupRefetch()}
      />

      <EditBookGroupNameDialog
        existingBookGroupNameData={state.selectedBookGroup}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            editBookGroupDialog: false,
          }))
        }
        open={state.editBookGroupDialog}
        refetch={() => getAggregatedBookGroupRefetch()}
      />

      <DeleteDialog
        handleClose={() =>
          setState((prev) => ({
            ...prev,
            deleteBookGroupDialog: false,
          }))
        }
        handleDelete={() => {
          deleteBookGroupMutate(state.selectedBookGroup?._id);
        }}
        loading={deleteBookGroupLoading}
        open={state.deleteBookGroupDialog}
        title={state.selectedBookGroup?.bookGroupName}
      />

      <AddBookGroupDialog
        open={Boolean(state.addBookGroupClassDocId)}
        onClose={() =>
          setState((prev) => ({ ...prev, addBookGroupClassDocId: "" }))
        }
        refetch={() => getAggregatedBookGroupRefetch()}
        classDocId={state.addBookGroupClassDocId}
      />

      <Box sx={{ mt: 2 }}>
        <CommonTable1
          columns={columns}
          data={state.tableData ?? []}
          maxHeight="70vh"
        />
      </Box>
    </>
  );
};

export default BookGroup;
