import React from "react";
import CommonDialog from "../../../CommonDialog";
import { Button, Grid, Typography } from "@mui/material";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { CustomTextFieldDisabled } from "assets/styles/globalStyledComponent";
import CommonTable1 from "app/components/Tables/CommonTable1";
import TableActionButtons from "app/components/common/TableActionButtons";
import {
  deleteBookFromBookGroup,
  getBookGroup,
} from "app/services/schoolService/ledgerNaccounts.service";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import CustomLoader from "app/components/common/CustomLoader";
import AddBookInGroup from "./AddBookInGroup";
import UpdateBookSale from "./UpdateBookSale";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import { IndianCurrency } from "app/utils/helper";
import { selectActiveSession } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";

const TableViewOfBookGroup = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  selectedBookGroupData = {},
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const [state, setState] = React.useState({
    addBookDialog: false,
    editBookDialog: false,
    deleteBookDialog: false,
    selectedBook: {},
    tableData: [],
    rateDialog: false,
  });

  const handleClose = () => {
    onClose();
  };

  const columns = [
    {
      header: "Sr.No",
      Cell: ({
        cell: {
          row: { id },
        },
      }) => (
        <Typography>
          {parseInt(id) + 1 < 10 ? `0${parseInt(id) + 1}` : parseInt(id) + 1}
        </Typography>
      ),
    },
    {
      header: "Book Title",
      accessorKey: "bookTitle",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.bookTitle ?? "--"}</Typography>;
      },
    },
    {
      header: "Buying Rate",
      accessorKey: "buyingRate",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{IndianCurrency(original?.buyingRate)}</Typography>;
      },
    },
    {
      header: "Selling Rate",
      accessorKey: "sellingRate",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{IndianCurrency(original?.sellingRate)}</Typography>;
      },
    },
    {
      header: "Actions",
      accessorKey: "Actions",
      Cell: ({ cell }) => {
        return (
          <TableActionButtons
            showEdit={true}
            showDelete={true}
            handleEdit={() => {
              setState((prev) => ({
                ...prev,
                rateDialog: true,
                selectedBook: cell.row.original,
              }));
            }}
            handleDelete={() => {
              setState((prev) => ({
                ...prev,
                deleteBookDialog: true,
                selectedBook: cell.row.original,
              }));
            }}
          />
        );
      },
    },
  ];

  const {
    refetch: getBookGroupRefetch,
    isLoading: getBookGroupLoading,
    isFetching: getBookGroupFetching,
  } = useQuery({
    queryKey: ["getBookGroup", selectedBookGroupData?._id],
    queryFn: () =>
      getBookGroup({
        session: selectedSession,
        bookGroupDocId: selectedBookGroupData?._id,
      }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        tableData: res?.data?.obj?.bookForSaleDocList,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    mutate: deleteBookFromBookGroupMutate,
    isLoading: deleteBookFromBookGroupLoading,
  } = useMutation("deleteBookFromBookGroup", deleteBookFromBookGroup, {
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        deleteBookDialog: false,
      }));
      getBookGroupRefetch();
      refetch();
      toast.success(success?.data?.message ?? "Book  Deleted Successfully");
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message ?? "Failed to delete Book ");
    },
  });

  return (
    <>
      <CustomLoader show={getBookGroupLoading || getBookGroupFetching} />
      <AddBookInGroup
        open={state?.addBookDialog}
        onClose={() => {
          setState((prev) => ({ ...prev, addBookDialog: false }));
        }}
        selectedBookGroup={selectedBookGroupData}
        refetch={() => {
          getBookGroupRefetch();
          refetch();
        }}
        classDocId={selectedBookGroupData?.classDocId}
      />

      <DeleteDialog
        handleClose={() =>
          setState((prev) => ({
            ...prev,
            deleteBookDialog: false,
          }))
        }
        handleDelete={() => {
          deleteBookFromBookGroupMutate({
            bookGroupDocId: selectedBookGroupData?._id,
            bookDocId: state?.selectedBook?._id,
          });
        }}
        loading={deleteBookFromBookGroupLoading}
        open={state.deleteBookDialog}
        title={state.selectedBook?.bookTitle}
      />

      <UpdateBookSale
        open={state?.rateDialog}
        onClose={() => {
          setState((prev) => ({ ...prev, rateDialog: false }));
        }}
        selectedBook={state?.selectedBook}
        refetch={() => {
          getBookGroupRefetch();
        }}
      />

      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="1020px"
        title={`Book Set: ${selectedBookGroupData?.bookGroupName}`}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: "20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={6}>
            <TextFieldLabel title={"Book Set Name"} />
            <CustomTextFieldDisabled
              fullWidth
              value={selectedBookGroupData?.bookGroupName ?? ""}
              size="medium"
            />
          </Grid>
          <Grid item xs={6} justifyContent={"flex-end"}>
            <Button
              onClick={() => {
                setState((prev) => ({ ...prev, addBookDialog: true }));
              }}
              variant="outlined"
              color="secondary"
              size="large"
              sx={{
                fontSize: { xs: "0.9rem", md: "1.2rem" },
                mt: 2,
                alignSelf: "flex-end",
                display: "flex",
              }}
            >
              + Add Book
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            padding: "20px",
          }}
        >
          <CommonTable1 columns={columns} data={state?.tableData ?? []} />
        </Grid>
      </CommonDialog>
    </>
  );
};

export default TableViewOfBookGroup;
