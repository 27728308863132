import { addBookInBookGroup } from "app/services/schoolService/ledgerNaccounts.service";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import CommonDialog from "../../../CommonDialog";
import { Grid, MenuItem, Stack } from "@mui/material";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { LoadingButton } from "@mui/lab";
import { getBooksForSale } from "app/services/ledgerAccount";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";

const AddBookInGroup = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  selectedBookGroup = {},
  classDocId = "",
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const [state, setState] = React.useState({
    selectedBook: "",
    list: [],
  });

  const handleClose = () => {
    onClose();
  };

  const {
    refetch: getBooksForSaleRefetch,
    isLoading: getBooksForSaleLoading,
    isFetching: getBooksForSaleFetching,
  } = useQuery({
    queryKey: ["getBooksForSale", classDocId],
    queryFn: () =>
      getBooksForSale({
        session: selectedSession,
        classDocId,
      }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        list: res?.data?.list ?? [],
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: classDocId && open ? true : false,
  });

  const {
    mutate: addBookInBookGroupMutate,
    isLoading: addBookInBookGroupLoading,
  } = useMutation("addBookInBookGroup", addBookInBookGroup, {
    onSuccess: (success) => {
      refetch();
      handleClose();
      toast.success(
        success?.data?.message ?? "Book Group Name Updated Successfully"
      );
    },
    onError: (err) => {
      toast.error(
        err?.response?.data?.message ?? "Failed to Update Book Group Name"
      );
    },
    onSettled: () => {
      setState((prev) => ({
        ...prev,
        selectedBook: "",
      }));
    },
  });

  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="700px"
        title="Add Book In Group"
      >
        {/* name and class */}
        <Grid
          container
          spacing={2}
          sx={{
            padding: "20px",
          }}
        >
          <Grid item xs={6}>
            <TextFieldLabel title={"Book Group Name"} />
            <CustomTextFieldDisabled value={selectedBookGroup?.bookGroupName} />
          </Grid>

          {/* <Grid item xs={6}>
            <TextFieldLabel title={"Class-Stream"} />
            <CustomTextFieldDisabled
              value={
                selectedBookGroup?.className ??
                "" + " " + selectedBookGroup?.section ??
                ""
              }
            />
          </Grid> */}
        </Grid>

        <Stack padding={2}>
          <TextFieldLabel title={"Select Book"} required />
          <CustomSelectBox
            MenuProps={MenuProps}
            size="medium"
            displayEmpty
            native
            value={state?.selectedBook}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                selectedBook: e?.target?.value,
              }));
            }}
          >
            <MenuItem component={"option"} value="">
              Select
            </MenuItem>
            {state?.list?.map((item) => (
              <MenuItem component={"option"} key={item._id} value={item._id}>
                {item?.bookTitle ?? "-- "}
              </MenuItem>
            ))}
          </CustomSelectBox>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          gap={2}
          sx={{ padding: "20px" }}
        >
          <LoadingButton
            color="primary"
            variant="contained"
            size="medium"
            type="submit"
            loading={addBookInBookGroupLoading}
            disabled={state.selectedBook === ""}
            onClick={() => {
              if (state.selectedBook === "") {
                toast.warn("Please select a book");
                return;
              }
              addBookInBookGroupMutate({
                bookGroupDocId: selectedBookGroup?._id,
                bookDocId: state.selectedBook,
              });
            }}
          >
            Submit
          </LoadingButton>
        </Stack>
      </CommonDialog>
    </>
  );
};

export default AddBookInGroup;
