import React, { useEffect, useState } from "react";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { getClassDropdownLogic } from "app/services/reception";
import { DatePicker } from "@mui/x-date-pickers";
import { Distrct, States } from "app/config/administrativeConstants";

import {
  GenderChoice,
  Community,
  Category,
} from "app/config/administrativeConstants";
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  CustomSelectBox,
  CustomTextArea,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import PreviewDownloadImage from "app/components/common/PreviewDownloadImage";
import { emailRegex } from "app/utils/helper";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import CustomLoader from "app/components/common/CustomLoader";
import {
  addAdmissionEnquiry,
  updateAdmissionEnquiry,
} from "app/services/schoolService/admissionEnquiry.service";
import { Controller, useForm } from "react-hook-form";
import useFolderWiseFileUploader from "app/hooks/upload/useFolderWiseFileUploader";
const initialState = {
  imageloading: false,
  enquiryDate: new Date(),
  formNumber: "",
  classDocId: "",

  // Personal Information
  profilePic: "",
  firstName: "",
  middleName: "",
  lastName: "",
  mobileNumber: "",
  whatsAppNumber: "",
  gender: "",
  community: "",
  category: "",
  email: "",
  aadharNumber: "",
  livingType: "",

  // Parent’s information
  father_firstName: "",
  father_middleName: "",
  father_lastName: "",
  father_mobileNumber: "",
  father_occupation: "",
  father_annualIncome: 0,

  mother_firstName: "",
  mother_middleName: "",
  mother_lastName: "",
  mother_mobileNumber: "",
  mother_occupation: "",
  mother_annualIncome: 0,

  guardian_firstName: "",
  guardian_middleName: "",
  guardian_lastName: "",
  guardian_mobileNumber: "",
  guardian_occupation: "",
  guardian_annualIncome: 0,
  guardian_relation: "",

  // Address Information
  current_address: "",
  current_dist: "",
  current_pinCode: "",
  current_state: "",

  // previous school
  previousSchoolName: "",
  previousSchoolClass: "",
  previousSchoolProbability: "",
  // reference detail
  referenceBy: "",
  referenceMobileNumber: "",
  whatDiscussed: "",
  whatToBeDiscussed: "",
  latitude: "",
  longitude: "",
};
const ErrorText = ({ text }) => {
  return <Typography color="error">{text}</Typography>;
};

const AddEnquiryForm2 = ({
  edit = false,
  editData = {},
  add = false,
  refetch,
  handleClose = () => {},
}) => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [state, setState] = useState({
    enquiryDate: new Date(),
    nextFollowUpDate: new Date(),
    imageloading: "",
    profilePic: "",
    addDialog: false,
  });
  const { control, watch, reset, handleSubmit } = useForm({
    defaultValues: initialState,
  });

  const handleSubmitForm = (data) => {
    let isAddressEmpty =
      data?.current_address === "" &&
      data?.current_dist === "" &&
      data?.current_pinCode === "" &&
      data?.current_state === "";
    let body = {
      session: selectedSession,
      enquiryDate: new Date(state?.enquiryDate),
      formNumber: data?.formNumber,
      classDocId: data?.classDocId,

      // Personal Information
      profilePic: data?.profilePic,
      firstName: data?.firstName,
      middleName: data?.middleName,
      lastName: data?.lastName,
      mobileNumber: data?.mobileNumber,
      gender: data?.gender,
      community: data?.community,
      category: data?.category,

      // Parent’s information
      fatherInfo: {
        firstName: data?.father_firstName,
        middleName: data?.father_middleName,
        lastName: data?.father_lastName,
        mobileNumber: data?.father_mobileNumber,
        occupation: data?.father_occupation,
        annualIncome: data?.father_annualIncome,
      },
      motherInfo: {
        firstName: data?.mother_firstName,
        middleName: data?.mother_middleName,
        lastName: data?.mother_lastName,
        mobileNumber: data?.mother_mobileNumber,
        occupation: data?.mother_occupation,
        annualIncome: data?.mother_annualIncome,
      },
      guardianInfo: {
        firstName: data?.guardianInfo_firstName,
        middleName: data?.guardianInfo_middleName,
        lastName: data?.guardianInfo_lastName,
        mobileNumber: data?.guardianInfo_mobileNumber,
        occupation: data?.guardianInfo_occupation,
        // annualIncome: data?.guardianInfo_annualIncome,
        relation: data?.guardianInfo_relation ?? "",
      },
      // Address Information
      currentAddress: isAddressEmpty
        ? null
        : {
            address: data?.current_address,
            dist: data?.current_dist,
            pinCode: data?.current_pinCode,
            state: data?.current_state,
          },
    };
    if (add) {
      addAdmissionEnquiryMutate(body);
    } else if (edit) {
      let editBody = {
        session: selectedSession,
        enquiryDate: new Date(state?.enquiryDate),
        classDocId: data?.classDocId,

        // Personal Information
        profilePic: data?.profilePic,
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        mobileNumber: data?.mobileNumber,
        gender: data?.gender,
        community: data?.community,
        category: data?.category,

        // Parent’s information
        fatherInfo: {
          firstName: data?.father_firstName,
          middleName: data?.father_middleName,
          lastName: data?.father_lastName,
          mobileNumber: data?.father_mobileNumber,
          occupation: data?.father_occupation,
          annualIncome: data?.father_annualIncome,
        },
        motherInfo: {
          firstName: data?.mother_firstName,
          middleName: data?.mother_middleName,
          lastName: data?.mother_lastName,
          mobileNumber: data?.mother_mobileNumber,
          occupation: data?.mother_occupation,
          annualIncome: data?.mother_annualIncome,
        },
        guardianInfo: {
          firstName: data?.guardianInfo_firstName,
          middleName: data?.guardianInfo_middleName,
          lastName: data?.guardianInfo_lastName,
          mobileNumber: data?.guardianInfo_mobileNumber,
          occupation: data?.guardianInfo_occupation,
          // annualIncome: data?.guardianInfo_annualIncome,
          relation: data?.guardianInfo_relation ?? "",
        },
        // Address Information
        currentAddress: {
          address: data?.current_address,
          dist: data?.current_dist,
          pinCode: data?.current_pinCode,
          state: data?.current_state,
        },
      };
      updateAdmissionEnquiryMutate({
        ...editBody,
        admissionEnquiryDocId: editData?._id,
      });
    }
  };

  const { handleUploadImage } = useFolderWiseFileUploader();
  const handleUpload = async (e) => {
    setState((prev) => ({
      ...prev,
      imageloading: true,
    }));
    try {
      let attachmentUrl = await handleUploadImage(
        false,
        e,
        "withSession",
        "reception",
        "admissionEnquiry"
      );
      setState((prev) => ({
        ...prev,
        profilePic: attachmentUrl.fileURL,
        imageloading: false,
      }));
    } catch (error) {
      toast.error(error);
      setState((prev) => ({
        ...prev,
        imageloading: false,
      }));
    }
  };

  const closeDialog = () => {
    handleClose();
    reset();
  };

  const { data: classData } = useQuery({
    queryKey: ["getClassInfo", selectedSession, selectedMedium],
    queryFn: () =>
      getClassDropdownLogic({ schoolDocId, selectedMedium, selectedSession }),
  });
  const {
    mutate: addAdmissionEnquiryMutate,
    isLoading: addAdmissionEnquiryLoading,
  } = useMutation(addAdmissionEnquiry, {
    onSuccess: ({ data }) => {
      handleClose();
      toast.success(data?.message);
      refetch();
      closeDialog();
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const {
    mutate: updateAdmissionEnquiryMutate,
    isLoading: updateAdmissionEnquiryLoading,
  } = useMutation(updateAdmissionEnquiry, {
    onSuccess: ({ data }) => {
      handleClose();
      toast.success(data?.message);
      refetch();
      closeDialog();
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  useEffect(() => {
    if (edit) {
      reset({
        enquiryDate: editData?.enquiryDate,
        formNumber: editData?.formNumber,
        classDocId: editData?.classDocId,

        profilePic: editData?.profilePic,
        firstName: editData?.firstName,
        middleName: editData?.middleName,
        lastName: editData?.lastName,

        mobileNumber: editData?.mobileNumber,
        gender: editData?.gender,
        community: editData?.community,
        category: editData?.category,

        // Parent’s information
        father_firstName: editData?.fatherInfo?.firstName,
        father_middleName: editData?.fatherInfo?.middleName,
        father_lastName: editData?.fatherInfo?.lastName,
        father_mobileNumber: editData?.fatherInfo?.mobileNumber,
        father_occupation: editData?.fatherInfo?.occupation,
        father_annualIncome: editData?.fatherInfo?.annualIncome,

        mother_firstName: editData?.motherInfo?.firstName,
        mother_middleName: editData?.motherInfo?.middleName,
        mother_lastName: editData?.motherInfo?.lastName,
        mother_mobileNumber: editData?.motherInfo?.mobileNumber,
        mother_occupation: editData?.motherInfo?.occupation,
        mother_annualIncome: editData?.motherInfo?.annualIncome,

        guardianInfo_firstName: editData?.guardianInfo?.firstName,
        guardianInfo_middleName: editData?.guardianInfo?.middleName,
        guardianInfo_lastName: editData?.guardianInfo?.lastName,
        guardianInfo_mobileNumber: editData?.guardianInfo?.mobileNumber,
        guardianInfo_occupation: editData?.guardianInfo?.occupation,
        guardianInfo_annualIncome: editData?.guardianInfo?.annualIncome,
        guardianInfo_relation: editData?.guardianInfo?.annualIncome,

        // Address Information
        current_address: editData?.currentAddress?.address,
        current_dist: editData?.currentAddress?.dist,
        current_pinCode: editData?.currentAddress?.pinCode,
        current_state: editData?.currentAddress?.state,
      });
    }
  }, [edit]);
  useEffect(() => {
    if (add) {
      setState((prev) => ({ ...prev, addDialog: true }));
    }
  }, [add]);
  const watch_state = watch("current_state");
  const districts = Distrct[States.indexOf(watch_state) + 1];

  return (
    <>
      <CustomLoader
        show={addAdmissionEnquiryLoading || updateAdmissionEnquiryLoading}
      />
      <CommonDialog
        open={add || edit}
        onClose={closeDialog}
        title={edit ? "Edit Admission Enquiry Form" : "Admission Enquiry Form"}
        minWidth="900px"
      >
        <DialogContent>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            {/* enquiry form information */}
            <Box>
              <Typography variant="h6" sx={{ my: 1 }}>
                Enquiry Form Information
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title="Enquiry Date" required />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        enquiryDate: e,
                      }))
                    }
                    value={state.enquiryDate}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        size="small"
                        fullWidth
                        error={!state.enquiryDate}
                        helperText={
                          !state.enquiryDate && "Enquiry Date required"
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"Class Stream"} required />
                  <Controller
                    control={control}
                    rules={{
                      required: "Class required",
                    }}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <CustomSelectBox
                          native
                          displayEmpty
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                        >
                          <MenuItem value="" component="option">
                            Select
                          </MenuItem>
                          {classData?.data?.list?.map((item) => (
                            <MenuItem
                              component="option"
                              key={item?.classDocId}
                              value={item?.classDocId}
                            >
                              {item?.className_stream}
                            </MenuItem>
                          ))}
                        </CustomSelectBox>
                        {!!error && <ErrorText text={error?.message} />}
                      </>
                    )}
                    name="classDocId"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"Form Number"} />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomTextField
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Type mobile number"
                        value={value}
                      />
                    )}
                    name="formNumber"
                  />
                </Grid>
              </Grid>
            </Box>
            {/* Student Info  */}
            <Box>
              <Typography variant="h6" sx={{ my: 1 }}>
                Student Information
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #3D398933",
                  backgroundColor: "##FBFBFF",
                  p: 1,
                  borderRadius: "10px",
                }}
              >
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* Photo  */}
                  <Grid item xs={3}>
                    <Box sx={{ pt: 2 }}>
                      <Stack direction="column" gap={1} justifyContent="center">
                        <Box sx={{ ml: 2.5 }}>
                          {state.profilePic ? (
                            <PreviewDownloadImage
                              src={state.profilePic}
                              deleteIcon={true}
                              handleDelete={() =>
                                setState((prev) => ({
                                  ...prev,
                                  profilePic: "",
                                }))
                              }
                            />
                          ) : (
                            <img
                              alt="ekalsutra"
                              src="/image-placeholder.jpeg"
                              style={{ height: "100px", width: "100px" }}
                            />
                          )}
                        </Box>
                        <Box>
                          <LoadingButton
                            loading={state.imageloading}
                            variant="outlined"
                            color="secondary"
                            size="large"
                            sx={{
                              borderRadius: "10px",
                              backgroundColor: "#fff",
                              textTransform: "capitalize",
                            }}
                            component="label"
                          >
                            <input
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={(e) => handleUpload(e)}
                            />
                            Upload
                          </LoadingButton>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  {/* Student Info  */}
                  <Grid item xs={9}>
                    <Grid container spacing={2}>
                      {/* firstName  */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title="First Name" required />
                        <Controller
                          control={control}
                          rules={{
                            required: "First name required",
                          }}
                          render={({
                            field: { onChange, onBlur, value },
                            fieldState: { error },
                          }) => (
                            <>
                              <CustomTextField
                                fullWidth
                                size="small"
                                onBlur={onBlur}
                                onChange={onChange}
                                placeholder="Type first name"
                                value={value}
                                error={!!error}
                              />
                              {!!error && <ErrorText text={error?.message} />}
                            </>
                          )}
                          name="firstName"
                        />
                      </Grid>
                      {/* middleName  */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title="Middle Name" />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomTextField
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder="Type middle name"
                              value={value}
                            />
                          )}
                          name="middleName"
                        />
                      </Grid>
                      {/* lastName  */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title="Last Name" />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomTextField
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder="Type last name"
                              value={value}
                            />
                          )}
                          name="lastName"
                        />
                      </Grid>
                      {/* gender */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title={"Gender"} />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomSelectBox
                              native
                              displayEmpty
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                            >
                              <MenuItem value="" component="option">
                                Select
                              </MenuItem>
                              {GenderChoice?.map((item) => (
                                <MenuItem
                                  key={item}
                                  value={item}
                                  component="option"
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </CustomSelectBox>
                          )}
                          name="gender"
                        />
                      </Grid>
                      {/* community */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title={"Community"} />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomSelectBox
                              native
                              displayEmpty
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                            >
                              <MenuItem value="" component="option">
                                Select
                              </MenuItem>
                              {Community?.map((item) => (
                                <MenuItem
                                  key={item}
                                  value={item}
                                  component="option"
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </CustomSelectBox>
                          )}
                          name="community"
                        />
                      </Grid>
                      {/* category */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title={"Category"} />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomSelectBox
                              native
                              displayEmpty
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                            >
                              <MenuItem value="" component="option">
                                Select
                              </MenuItem>
                              {Category?.map((item) => (
                                <MenuItem
                                  key={item}
                                  value={item}
                                  component="option"
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </CustomSelectBox>
                          )}
                          name="category"
                        />
                      </Grid>
                      {/* primary mobile number  */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel
                          title={"Primary Mobile Number"}
                          required
                        />
                        <Controller
                          rules={{
                            required: "Mobile number required",
                            minLength: {
                              value: 10,
                              message: "10 digit required",
                            },
                          }}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value },
                            fieldState: { error },
                          }) => (
                            <CustomTextField
                              type="number"
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={(e) => {
                                if (String(e.target.value).length < 11) {
                                  onChange(e);
                                }
                              }}
                              placeholder="Type mobile number"
                              value={value}
                              error={!!error}
                            />
                          )}
                          name="mobileNumber"
                        />
                      </Grid>
                      {/* WhatsApp number  */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title={"WhatsApp Number"} />
                        <Controller
                          rules={{
                            minLength: {
                              value: 10,
                              message: "10 digit required",
                            },
                          }}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value },
                            fieldState: { error },
                          }) => (
                            <CustomTextField
                              type="number"
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={(e) => {
                                if (String(e.target.value).length < 11) {
                                  onChange(e);
                                }
                              }}
                              placeholder="Type mobile number"
                              value={value}
                              error={!!error}
                            />
                          )}
                          name="whatsAppNumber"
                        />
                      </Grid>
                      {/* email  */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title={"Email"} />
                        <Controller
                          rules={{
                            pattern: {
                              value: emailRegex,
                              message: "Not valid email",
                            },
                          }}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value },
                            fieldState: { error },
                          }) => (
                            <CustomTextField
                              type="email"
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder="Type email"
                              value={value}
                              error={!!error}
                            />
                          )}
                          name="email"
                        />
                      </Grid>
                      {/* Living Type */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title={"Living Type"} />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomSelectBox
                              native
                              displayEmpty
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                            >
                              <MenuItem value="" component="option">
                                Select
                              </MenuItem>
                              {["Hosteler", "Day Scholar"]?.map((item) => (
                                <MenuItem
                                  key={item}
                                  value={item}
                                  component="option"
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </CustomSelectBox>
                          )}
                          name="livingType"
                        />
                      </Grid>
                      {/* aadhar number  */}
                      <Grid item xs={12} md={4}>
                        <TextFieldLabel title={"Aadhar Number"} />
                        <Controller
                          rules={{
                            minLength: {
                              value: 12,
                              message: "Aadhar should be 12 digit",
                            },
                          }}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value },
                            fieldState: { error },
                          }) => (
                            <CustomTextField
                              type="number"
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={(e) => {
                                if (String(e.target.value).length < 13) {
                                  onChange(e);
                                }
                              }}
                              placeholder="Type aadhar number"
                              value={value}
                              error={!!error}
                            />
                          )}
                          name="mobileNumber"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            {/* parent Info  */}
            <Box>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Parent’s Information
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #3D398933",
                  backgroundColor: "##FBFBFF",
                  p: 1,
                  borderRadius: "10px",
                }}
              >
                {/* Mother Info  */}
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Mother's Info
                </Typography>
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* First Name *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"First Name"} required />
                    <Controller
                      control={control}
                      rules={{
                        required: "First name required",
                      }}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <CustomTextField
                            fullWidth
                            size="small"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder="Type first name"
                            value={value}
                            error={!!error}
                          />
                          {!!error && <ErrorText text={error?.message} />}
                        </>
                      )}
                      name="mother_firstName"
                    />
                  </Grid>
                  {/* Middle Name *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Middle Name"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type middle name"
                          value={value}
                        />
                      )}
                      name="mother_middleName"
                    />
                  </Grid>
                  {/* Last Name *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Last Name"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type last name"
                          value={value}
                        />
                      )}
                      name="mother_lastName"
                    />
                  </Grid>
                  {/* mobileNumber  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Mobile Number"} />
                    <Controller
                      rules={{
                        minLength: {
                          value: 10,
                          message: "10 digit required",
                        },
                      }}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          type="number"
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (String(e.target.value).length < 11) {
                              onChange(e);
                            }
                          }}
                          placeholder="Type mobile number"
                          value={value}
                          error={!!error}
                        />
                      )}
                      name="mother_mobileNumber"
                    />
                  </Grid>
                  {/* occupation *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Occupation"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type occupation"
                          value={value}
                        />
                      )}
                      name="mother_occupation"
                    />
                  </Grid>
                  {/* Annual Income *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Annual Income"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type annual income"
                          value={value}
                        />
                      )}
                      name="mother_annualIncome"
                    />
                  </Grid>
                </Grid>
                {/* Father Info  */}
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Father's Info
                </Typography>
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* First Name *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"First Name"} required />
                    <Controller
                      control={control}
                      rules={{
                        required: "First name required",
                      }}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <CustomTextField
                            fullWidth
                            size="small"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder="Type first name"
                            value={value}
                            error={!!error}
                          />
                          {!!error && <ErrorText text={error?.message} />}
                        </>
                      )}
                      name="father_firstName"
                    />
                  </Grid>
                  {/* Middle Name *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Middle Name"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type middle name"
                          value={value}
                        />
                      )}
                      name="father_middleName"
                    />
                  </Grid>
                  {/* Last Name *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Last Name"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type last name"
                          value={value}
                        />
                      )}
                      name="father_lastName"
                    />
                  </Grid>
                  {/* mobileNumber  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Mobile Number"} />
                    <Controller
                      rules={{
                        minLength: {
                          value: 10,
                          message: "10 digit required",
                        },
                      }}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          type="number"
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (String(e.target.value).length < 11) {
                              onChange(e);
                            }
                          }}
                          placeholder="Type mobile number"
                          value={value}
                          error={!!error}
                        />
                      )}
                      name="father_mobileNumber"
                    />
                  </Grid>
                  {/* occupation *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Occupation"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type occupation"
                          value={value}
                        />
                      )}
                      name="father_occupation"
                    />
                  </Grid>
                  {/* Annual Income *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Annual Income"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          type="number"
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type annual income"
                          value={value}
                        />
                      )}
                      name="father_annualIncome"
                    />
                  </Grid>
                </Grid>
                {/* Guardian Info  */}
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Guardian's Info
                </Typography>
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* First Name *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"First Name"} required />
                    <Controller
                      rules={{
                        required: "First name required",
                      }}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <CustomTextField
                            fullWidth
                            size="small"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder="Type first name"
                            value={value}
                          />
                          {!!error && <ErrorText text={error?.message} />}
                        </>
                      )}
                      name="guardian_firstName"
                    />
                  </Grid>
                  {/* Middle Name *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Middle Name"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type middle name"
                          value={value}
                        />
                      )}
                      name="guardian_middleName"
                    />
                  </Grid>
                  {/* Last Name *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Last Name"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type last name"
                          value={value}
                        />
                      )}
                      name="guardian_lastName"
                    />
                  </Grid>
                  {/* mobileNumber  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Mobile Number"} />
                    <Controller
                      rules={{
                        minLength: {
                          value: 10,
                          message: "10 digit required",
                        },
                      }}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          type="number"
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (String(e.target.value).length < 11) {
                              onChange(e);
                            }
                          }}
                          placeholder="Type mobile number"
                          value={value}
                          error={!!error}
                        />
                      )}
                      name="guardian_mobileNumber"
                    />
                  </Grid>
                  {/* occupation *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Occupation"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type occupation"
                          value={value}
                        />
                      )}
                      name="guardian_occupation"
                    />
                  </Grid>
                  {/* relation *  */}
                  <Grid item xs={12} md={3}>
                    <TextFieldLabel title={"Relation"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type annual income"
                          value={value}
                        />
                      )}
                      name="guardian_relation"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            {/* Address Info  */}
            <Box>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Address Information
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #3D398933",
                  backgroundColor: "##FBFBFF",
                  p: 1,
                  borderRadius: "10px",
                }}
              >
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* adress  */}
                  <Grid item xs={12} md={6}>
                    <TextFieldLabel title={"Current Address"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomTextField
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Type current address"
                          value={value}
                        />
                      )}
                      name="current_address"
                    />
                  </Grid>
                  {/* pin code  */}
                  <Grid item xs={12} md={6}>
                    <TextFieldLabel title={"PIN Code"} />
                    <Controller
                      rules={{
                        minLength: {
                          value: 6,
                          message: "6 digit required",
                        },
                      }}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          type="number"
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (String(e.target.value).length < 7) {
                              onChange(e);
                            }
                          }}
                          placeholder="Type mobile number"
                          value={value}
                          error={!!error}
                        />
                      )}
                      name="current_pinCode"
                    />
                  </Grid>
                  {/* State  */}
                  <Grid item xs={12} md={6}>
                    <TextFieldLabel title={"State"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomSelectBox
                          native
                          displayEmpty
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        >
                          <MenuItem value="" disabled component="option">
                            Select
                          </MenuItem>
                          {States.map((state) => (
                            <MenuItem value={state} component="option">
                              {state}
                            </MenuItem>
                          ))}
                        </CustomSelectBox>
                      )}
                      name="current_state"
                    />
                  </Grid>
                  {/* District  */}
                  <Grid item xs={12} md={6}>
                    <TextFieldLabel title={"District"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomSelectBox
                          native
                          displayEmpty
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        >
                          <MenuItem value="" disabled component="option">
                            Select
                          </MenuItem>
                          {districts.length !== 1 &&
                            districts?.map((dist) => (
                              <MenuItem value={dist} component="option">
                                {dist}
                              </MenuItem>
                            ))}
                        </CustomSelectBox>
                      )}
                      name="current_dist"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Box>

            {/* previous school detail  */}
            <Box>
              <Typography variant="h6" sx={{ my: 1 }}>
                Previous School Detail
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #3D398933",
                  backgroundColor: "##FBFBFF",
                  p: 1,
                  borderRadius: "10px",
                }}
              >
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* Previous School Name */}
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Previous School Name"} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomSelectBox
                          native
                          displayEmpty
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        >
                          <MenuItem value="" component="option">
                            Select
                          </MenuItem>
                          <MenuItem value="new" component="option">
                            Add New
                          </MenuItem>
                        </CustomSelectBox>
                      )}
                      name="previousSchoolName"
                    />
                  </Grid>
                  {/* previous class */}
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Class Stream"} />
                    <Controller
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <CustomSelectBox
                          native
                          displayEmpty
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                        >
                          <MenuItem value="" component="option">
                            Select
                          </MenuItem>
                          {classData?.data?.list?.map((item) => (
                            <MenuItem
                              component="option"
                              key={item?.className_stream}
                              value={item?.className_stream}
                            >
                              {item?.className_stream}
                            </MenuItem>
                          ))}
                        </CustomSelectBox>
                      )}
                      name="previousSchoolClass"
                    />
                  </Grid>
                  {/* probability  */}
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Probability (0-100)"} />
                    <Controller
                      rules={{
                        max: {
                          value: 100,
                          message: "probability should be lass equal 100",
                        },
                        min: {
                          value: 1000,
                          message: "probability should be greater equal 0",
                        },
                      }}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          type="number"
                          fullWidth
                          size="small"
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (String(e.target.value).length < 11) {
                              onChange(e);
                            }
                          }}
                          placeholder="Type probability"
                          value={value}
                          error={!!error}
                        />
                      )}
                      name="previousSchoolProbability"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            {/* reference detail  */}

            <Stack
              sx={{
                border: "1px solid #3D398933",
                backgroundColor: "##FBFBFF",
                p: 1,
                borderRadius: "10px",
                mt: 1,
              }}
            >
              <Grid container rowSpacing={0.8} columnSpacing={2} sx={{ pt: 2 }}>
                {/* reference by Name */}
                <Grid item xs={12} md={4}>
                  <TextFieldLabel title={"Reference By (if any)"} />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomTextField
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Reference by"
                        value={value}
                      />
                    )}
                    name="referenceBy"
                  />
                </Grid>
                {/* mobile number */}
                <Grid item xs={12} md={4}>
                  <TextFieldLabel title={"Reference Mobile Number"} />
                  <Controller
                    rules={{
                      minLength: {
                        value: 10,
                        message: "10 digit required",
                      },
                    }}
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        type="number"
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={(e) => {
                          if (String(e.target.value).length < 11) {
                            onChange(e);
                          }
                        }}
                        placeholder="Type mobile number"
                        value={value}
                        error={!!error}
                      />
                    )}
                    name="referenceMobileNumber"
                  />
                </Grid>
                {/* next follow up date */}
                <Grid item xs={12} md={4}>
                  <TextFieldLabel title="Next Follow Up Date" required />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        nextFollowUpDate: e,
                      }))
                    }
                    value={state.nextFollowUpDate}
                    renderInput={(params) => (
                      <CustomTextField {...params} size="small" fullWidth />
                    )}
                  />
                </Grid>
                {/* what discussed  */}
                <Grid item xs={12} md={6}>
                  <TextFieldLabel title={"What Discussed ?"} />
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextArea
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Type discussed text..."
                        value={value}
                        error={!!error}
                      />
                    )}
                    name="whatDiscussed"
                  />
                </Grid>
                {/* what discussed  */}
                <Grid item xs={12} md={6}>
                  <TextFieldLabel title={"What to be Discussed ?"} />
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextArea
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Type discussed text..."
                        value={value}
                        error={!!error}
                      />
                    )}
                    name="whatToBeDiscussed"
                  />
                </Grid>
              </Grid>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="secondary"
            form="admission-enquiry-form"
            loading={addAdmissionEnquiryLoading}
            variant="contained"
            size="large"
            type="submit"
            sx={{
              width: "10rem",
            }}
          >
            {edit ? "Update" : "Submit"}
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddEnquiryForm2;
