import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Helmet } from "react-helmet";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import { Container } from "@mui/material";
import FeeCollectionMain from "./FeeCollectionMain";
import FeeReportTable from "../fee-report/FeeReportTable";
import FeeReportDeletedFee from "../fee-report/FeeReportDeletedFee";
import ByAccountantReport from "../fee-report/ByAccountantReport";
import ConvertInvoice from "../fee-report/ConvertInvoice";

export default function FeesPage() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container maxWidth="xl">
        <TabContext value={value}>
          <TabsWrapper>
            <TabList onChange={handleChange}>
              <Tab label="Fee Collection" value="1" />
              <Tab label="Old Invoice" value="2" />
              <Tab label="Old Deleted Invoice" value="3" />
              <Tab label="By Accountant" value="4" />
              <Tab label="Convert Invoice" value="5" />
            </TabList>
          </TabsWrapper>
          <TabPanel value="1" sx={{ p: "0 !important" }}>
            <Box sx={{ py: 1.5 }}>
              <FeeCollectionMain />
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ p: "0 !important" }}>
            <Box sx={{ my: 1.5, px: 1 }}>
              <FeeReportTable />
            </Box>
          </TabPanel>
          <TabPanel value="3" sx={{ p: "0 !important" }}>
            <Box sx={{ my: 1.5, px: 1 }}>
              <FeeReportDeletedFee />
            </Box>
          </TabPanel>
          <TabPanel value="4" sx={{ p: "0 !important" }}>
            <Box sx={{ my: 1.5, px: 1 }}>
              <ByAccountantReport />
            </Box>
          </TabPanel>
          <TabPanel value="5" sx={{ p: "0 !important" }}>
            <Box sx={{ my: 1.5, px: 1 }}>
              <ConvertInvoice />
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
}
