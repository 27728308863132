import React, { useEffect, useRef, useState } from "react";
import CommonDialog from "../CommonDialog";
import { CheckCircle, Close, InfoOutlined, Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Grid,
  Box,
  Typography,
  styled,
  Stack,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  DialogActions,
  Divider,
  IconButton,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import {
  convertOldInvoiceToNewInvoice,
  getDistinctFeeCollection,
  getFeeCollection,
  getOldInvoice,
} from "app/services/management";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import { toast } from "react-toastify";
import CustomLoader from "../../CustomLoader";
import { THEME } from "app/config/constants";
import { getFeeInvoice } from "app/services/schoolService/newFee.service";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";
import { IndianCurrency } from "app/utils/helper";
import DialogHeader from "../../DialogHeader";
import TextFieldLabel from "../../TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import useFolderWiseFileUploader from "app/hooks/upload/useFolderWiseFileUploader";

const LabelText = ({
  label,
  text,
  color = THEME.primary,
  onClickText = () => {},
  clickable = false,
}) => {
  return (
    <>
      <Typography
        sx={{
          color: THEME.primary,
          opacity: 0.75,
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        {label}
      </Typography>
      <Typography
        onClick={onClickText}
        sx={{
          color,
          fontSize: "14px",
          fontWeight: 600,
          cursor: clickable && "pointer",
          textDecoration: clickable && "underline",
        }}
      >
        {text}
      </Typography>
    </>
  );
};
const DiscountDialog = ({ state, setState }) => {
  const [loading, setLoading] = useState(false);
  const [discountState, setDiscountState] = useState({
    loading: false,
    givingExtraDiscountAmount: 0,
    remark: "",
    attachmentUrl: "",
    transactionDetail: false,
    invoiceIndex: 0,
    invoiceNumber: "",
    remarkError: false,
  });
  const { handleUploadImage } = useFolderWiseFileUploader();
  const handleUpload = async (e) => {
    setLoading(true);
    try {
      const attachmentUrl = await handleUploadImage(
        false,
        e,
        "withSession",
        "FeeManagement",
        "Discount"
      );
      state.feeList[state.discountIndex]["discountAttachment"] =
        attachmentUrl.fileURL;

      setLoading(false);
      setState((prev) => ({ ...prev }));
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  const { isLoading: getFeeInvoiceLoading, isFetching: getFeeInvoiceFetching } =
    useQuery({
      queryKey: ["getFeeInvoice", discountState.invoiceNumber],
      queryFn: () =>
        getFeeInvoice({ invoiceNumber: discountState.invoiceNumber }),
      onSuccess: (success) => {
        state.feeList[state.discountIndex]["feeTrasactions"][
          discountState.invoiceIndex
        ] = {
          ...state.feeList[state.discountIndex]["feeTrasactions"][
            discountState.invoiceIndex
          ],
          createdBy: success?.data?.obj?.createdBy,
          createdOn: success?.data?.obj?.createdOn,
          approveddBy: success?.data?.obj?.approveddBy,
          approveddOn: success?.data?.obj?.createdOn,
        };

        setState((prev) => ({ ...prev }));
        setDiscountState((prev) => ({
          ...prev,
          invoiceIndex: 0,
          invoiceNumber: "",
        }));
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: discountState.invoiceNumber ? true : false,
    });
  const payingNow =
    Number(state?.feeList[state.discountIndex]?.feeAmount ?? 0) -
    Number(state?.feeList[state.discountIndex]?.t_paidFeeAmount ?? 0) -
    Number(discountState.givingExtraDiscountAmount ?? 0);
  // -     Number(state?.feeList[state.discountIndex]?.t_extraDiscountAmount ?? 0);
  const balanceAmount =
    Number(state.feeList[state.discountIndex]?.feeAmount ?? 0) -
    // Number(state.feeList[state.discountIndex]?.t_extraDiscountAmount ?? 0) -
    Number(discountState.givingExtraDiscountAmount ?? 0) -
    Number(state.feeList[state.discountIndex]?.t_paidFeeAmount ?? 0) -
    Number(payingNow ?? 0);
  const handleSubmit = () => {
    if (
      Number(discountState.givingExtraDiscountAmount) !== 0 &&
      !String(discountState?.remark).trim()
    ) {
      setDiscountState((prev) => ({ ...prev, remarkError: true }));
      return;
    }
    state.feeList[state.discountIndex]["givingExtraDiscountAmount"] =
      discountState.givingExtraDiscountAmount;
    state.feeList[state.discountIndex]["discountRemarks"] =
      discountState.remark;
    state.feeList[state.discountIndex]["extraDiscountDocLink"] =
      discountState.attachmentUrl;
    state.feeList[state.discountIndex]["balance"] = balanceAmount;
    state.feeList[state.discountIndex]["payingNow"] = payingNow;
    handleCloseDialog();
  };
  const handleCloseDialog = () => {
    setLoading(false);
    setState((prev) => ({
      ...prev,
      discountDialog: false,
      discountIndex: 0,
    }));
    setDiscountState((prev) => ({
      ...prev,
      loading: false,
      givingExtraDiscountAmount: 0,
      remark: "",
      attachmentUrl: "",
    }));
  };
  useEffect(() => {
    if (state.discountDialog) {
      setDiscountState({
        givingExtraDiscountAmount:
          state?.feeList[state.discountIndex]?.givingExtraDiscountAmount,
        remark: state?.feeList[state.discountIndex]?.discountRemarks,
        attachmentUrl: state.feeList[state.discountIndex]?.discountAttachment,
      });
    }
  }, [state.discountDialog]);

  return (
    <>
      <CustomLoader show={getFeeInvoiceLoading || getFeeInvoiceFetching} />
      {/* transaction detail dialog */}
      <Dialog
        open={discountState.transactionDetail}
        onClose={() =>
          setDiscountState((prev) => ({ ...prev, transactionDetail: false }))
        }
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "900px" } },
        }}
      >
        <InvoiceHeading>
          <Grid container spacing={1} p={1} columns={15}>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">
                Invoice Date
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="invoice_head__text">
                Invoice Number
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Discount</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Remarks</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Attachment</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Created By</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">
                Approved By
              </Typography>
            </Grid>
          </Grid>
        </InvoiceHeading>
        {state?.feeList[state.discountIndex]?.feeTrasactions?.map((item, i) => (
          <Box key={i}>
            <Grid
              container
              spacing={1}
              p={1}
              columns={15}
              display={"flex"}
              alignItems={"center"}
            >
              <Grid item xs={2}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  {dayjs(item?.invoiceCreatedOn).format("DD MMM, YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Link
                  to={`/${appRoutes.NewInvoice}/${String(
                    item?.invoiceNumber
                  ).replace(/\//g, " ")}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: "bold" }}
                >
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {item?.invoiceNumber}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  {IndianCurrency(item?.extraDiscountAmount)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  {item?.extraDiscountRemarks}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {item?.extraDiscountDocLink ? (
                  <a
                    href={item?.extraDiscountDocLink}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    view
                  </a>
                ) : (
                  <Typography>--</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                {item?.createdOn ? (
                  <>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {item?.createdBy?.personName}
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {dayjs(item?.createdOn).format("DD MMM, YYYY")}
                    </Typography>
                  </>
                ) : (
                  <IconButton
                    onClick={() =>
                      setDiscountState((prev) => ({
                        ...prev,
                        invoiceIndex: i,
                        invoiceNumber: item?.invoiceNumber,
                      }))
                    }
                  >
                    <Refresh sx={{ color: "blue" }} />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={2}>
                {item?.approvedOn ? (
                  <>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {item?.approvedBy?.personName}
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {dayjs(item?.approvedOn).format("DD MMM, YYYY")}
                    </Typography>
                  </>
                ) : (
                  <IconButton
                    onClick={() =>
                      setDiscountState((prev) => ({
                        ...prev,
                        invoiceIndex: i,
                        invoiceNumber: item?.invoiceNumber,
                      }))
                    }
                  >
                    <Refresh sx={{ color: "blue" }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <Divider
              sx={{ background: (theme) => theme.palette.primary.main }}
            />
          </Box>
        ))}
      </Dialog>
      <Dialog
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "800px" } },
        }}
        open={state.discountDialog}
        onClose={() => handleCloseDialog()}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            handleClose={() => handleCloseDialog()}
            title="Total Discount Detail"
          />
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mt: 1 }} />
          <ShowDetailBox>
            <Grid container spacing={1} columns={18}>
              <Grid item md={4} xs={9}>
                <LabelText
                  label={"Fee Type"}
                  text={state?.feeList[state.discountIndex]?.feeType}
                />
              </Grid>
              <Grid item md={4} xs={9}>
                <LabelText
                  label={"Month-Year"}
                  text={`${state?.feeList[state.discountIndex]?.month}-${
                    state?.feeList[state.discountIndex]?.year
                  }`}
                />
              </Grid>
              <Grid item md={4} xs={9}>
                <LabelText
                  label={"General Fee Amount"}
                  text={IndianCurrency(
                    state?.feeList[state.discountIndex]?.generalFeeAmount
                  )}
                />
              </Grid>
              <Grid item md={4} xs={9}>
                <LabelText
                  label={"Category Discount"}
                  text={IndianCurrency(
                    state?.feeList[state.discountIndex]?.categoryDiscountAmount
                  )}
                />
              </Grid>
              <Grid item md={4} xs={9}>
                <LabelText
                  label={"Final Fee Amount"}
                  text={IndianCurrency(
                    state?.feeList[state.discountIndex]?.feeAmount
                  )}
                />
              </Grid>
              <Grid item md={4} xs={9}>
                <LabelText
                  label={"Recurring Discount"}
                  text={IndianCurrency(
                    state?.feeList[state.discountIndex]?.recurringDiscountAmount
                  )}
                />
              </Grid>
              <Grid item md={4} xs={9}>
                <LabelText
                  color="#2789FD"
                  label={"Total Extra Discount"}
                  text={IndianCurrency(
                    state?.feeList[state.discountIndex]?.t_extraDiscountAmount
                  )}
                  onClickText={() =>
                    setDiscountState((prev) => ({
                      ...prev,
                      transactionDetail: true,
                    }))
                  }
                  clickable
                />
              </Grid>
              <Grid item md={4} xs={9}>
                <LabelText
                  color="#49A600"
                  label={"Total Paid Amount"}
                  text={IndianCurrency(
                    state?.feeList[state.discountIndex]?.t_paidFeeAmount
                  )}
                />
              </Grid>
              <Grid item md={4} xs={9}>
                <LabelText
                  color="#F10F0F"
                  label={"Total Due Amount"}
                  text={IndianCurrency(
                    state?.feeList[state.discountIndex]?.t_balanceFeeAmount
                  )}
                />
              </Grid>
            </Grid>
          </ShowDetailBox>
          <Grid container spacing={4} sx={{ py: 2 }} columns={13}>
            <Grid item md={3} xs={6}>
              <TextFieldLabel title={"Discount Amount"} />
              <CustomTextField
                type="number"
                fullWidth
                size="small"
                value={discountState.givingExtraDiscountAmount}
                onChange={(e) => {
                  setDiscountState((prev) => ({
                    ...prev,
                    givingExtraDiscountAmount: e.target.value,
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>&#8377;</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={5} xs={7}>
              <TextFieldLabel title={"Discount Remarks"} />
              <CustomTextField
                fullWidth
                size="small"
                value={discountState.remark}
                onChange={(e) => {
                  setDiscountState((prev) => ({
                    ...prev,
                    remark: e.target.value,
                    remarkError: false,
                  }));
                }}
                placeholder="Type discount remark"
                error={discountState.remarkError}
              />
              {discountState.remarkError && (
                <Typography color="error">remark required</Typography>
              )}
            </Grid>
            <Grid item md={5} xs={13}>
              <TextFieldLabel title={"Attachment"} />
              {state.feeList[state.discountIndex]?.discountAttachment && (
                <Stack
                  direction={"row"}
                  gap={1.5}
                  alignItems={"center"}
                  pb={0.5}
                >
                  <Typography>
                    {
                      String(
                        state.feeList[state.discountIndex]?.discountAttachment
                      )?.split("discount/")[1]
                    }
                  </Typography>
                  <Close
                    color="error"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      state.feeList[state.discountIndex]["discountAttachment"] =
                        "";
                      setState((prev) => ({ ...prev }));
                    }}
                  />
                </Stack>
              )}
              <LoadingButton
                loading={loading}
                component={"label"}
                variant="outlined"
                color="secondary"
                size="large"
                sx={{ textTransform: "capitalize" }}
              >
                <input
                  hidden
                  type="file"
                  accept="image/*,application/pdf"
                  onChange={(e) => handleUpload(e)}
                />
                Upload
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            sx={{ textTransform: "capitalize" }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            // disabled={status === "Paid" ? true : false}
            variant="contained"
            size="large"
            sx={{ textTransform: "capitalize" }}
            color="secondary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ConvertInvoiceDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
}) => {
  const [falseState, setFalseState] = useState(false);
  const [state, setState] = useState({
    feeList: [],
    feeObj: {},
    discountDialog: false,
    discountIndex: 0,
    transactionDetail: false,
    transactionShowIndex: 0,
    invoiceIndex: 0,
    invoiceNumber: "",
    studentDocId: "",
    distinct: "",
    feeCollectionDocId: "",
  });
  const selectedSession = useSelector(selectActiveSession);

  const calulateTotalAmount = () => {
    const filteredData = state.feeList.filter((item) => item.checked);
    let total = 0;
    filteredData.forEach((item) => {
      total += Number(item?.payingNow) ?? 0;
    });
    return total;
  };
  const calulatediscount = () => {
    const filteredData = state.feeList.filter((item) => item.checked);
    let total = 0;
    filteredData.forEach((item) => {
      total +=
        // Number(item?.t_extraDiscountAmount ?? 0) +
        Number(item?.givingExtraDiscountAmount ?? 0) +
        Number(item?.recurringDiscountAmount ?? 0);
    });
    return total;
  };
  const calulatepaidAmount = () => {
    const filteredData = state.feeList.filter((item) => item.checked);
    let total = 0;
    filteredData.forEach((item) => {
      total += Number(item?.t_paidFeeAmount ?? 0);
    });
    return total;
  };
  const calulatebalaceAmount = () => {
    const filteredData = state.feeList.filter((item) => item.checked);

    let total = 0;
    filteredData.forEach((item) => {
      total += Number(item?.balance ?? 0);
    });
    return total;
  };
  const handleUpdate = () => {
    const body = {
      invoiceDocId: open,
      feeCollectionList: state.feeList.map((item) => ({
        feeCollectionDocId: item?._id,
        givingExtraDiscountAmount: Number(item?.givingExtraDiscountAmount ?? 0),
        extraDiscountRemarks: item?.discountRemarks ?? "",
        payingFeeAmount: Number(item?.payingNow ?? 0),
        balanceAmount: Number(item?.balance ?? 0),
        balanceRemark: item?.balanceRemark ?? "",
      })),
      totalPayingFeeAmount: calulateTotalAmount() ?? 0,
      totalGivingDiscountAmount: calulatediscount() ?? 0,
      totalBalanceFeeAmount: calulatebalaceAmount() ?? 0,
    };
    convertOldInvoiceToNewInvoiceMutate(body);
  };
  const handleCloseDialog = () => {
    setState((prev) => ({
      ...prev,
      feeList: [],
      feeObj: {},
      studentDocId: "",
      distinct: "",
      feeCollectionDocId: "",
    }));
    onClose();
  };
  const handleAddExtraFeeCollection = () => {
    const filteredData = getFeeCollectionData?.data?.list?.find(
      (item) => item?._id === state.feeCollectionDocId
    );
    const t_paidFeeAmount = filteredData?.feeTrasactions
      ? filteredData?.feeTrasactions?.reduce(
          (total, value) => total + Number(value?.trasactionAmount ?? 0),
          0
        )
      : 0;
    const t_extraDiscountAmount = filteredData?.feeTrasactions
      ? filteredData?.feeTrasactions?.reduce(
          (total, value) => total + Number(value?.extraDiscountAmount ?? 0),
          0
        )
      : 0;
    const recurringDiscountAmount = filteredData?.feeTrasactions
      ? filteredData?.feeTrasactions?.reduce(
          (total, value) => total + Number(value?.recurringDiscountAmount ?? 0),
          0
        )
      : 0;
    const newBody = {
      ...filteredData,
      balanceRemark: "",
      discountRemarks: "",
      extraDiscountDocLink: "",
      givingExtraDiscountAmount: 0,
      waivingFine: 0,
      payingFine: 0,
      waivingFineRemark: "",
      checked: true,
      t_paidFeeAmount,
      t_extraDiscountAmount,
      recurringDiscountAmount,
      payingNow:
        Number(filteredData?.feeAmount ?? 0) -
        t_paidFeeAmount -
        t_extraDiscountAmount -
        recurringDiscountAmount,
    };
    setState((prev) => ({
      ...prev,
      distinct: "",
      feeCollectionDocId: "",
      feeList: [...prev.feeList, newBody],
    }));
  };
  const {
    mutate: convertOldInvoiceToNewInvoiceMutate,
    isLoading: convertOldInvoiceToNewInvoiceLoading,
  } = useMutation(
    "convertOldInvoiceToNewInvoice",
    convertOldInvoiceToNewInvoice,
    {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        onClose();
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  const { isLoading: getOldInvoiceLoading, isFetching: getOldInvoiceFetching } =
    useQuery({
      queryKey: ["getOldInvoice", open],
      queryFn: () =>
        getOldInvoice({
          session: selectedSession,
          invoiceDocId: open,
        }),
      onSuccess: (success) => {
        setState((prev) => ({
          ...prev,
          feeList: success?.data?.feeCollectionList?.map((res) => {
            const t_paidFeeAmount = res?.feeTrasactions
              ? res?.feeTrasactions?.reduce(
                  (total, value) =>
                    total +
                    (value.status === "Paid"
                      ? Number(value?.trasactionAmount ?? 0)
                      : 0),
                  0
                )
              : 0;
            const t_extraDiscountAmount = res?.feeTrasactions
              ? res?.feeTrasactions?.reduce(
                  (total, value) =>
                    total +
                    (value.status === "Paid"
                      ? Number(value?.extraDiscountAmount ?? 0)
                      : 0),
                  0
                )
              : 0;
            const recurringDiscountAmount = res?.feeTrasactions
              ? res?.feeTrasactions?.reduce(
                  (total, value) =>
                    total +
                    (value.status === "Paid"
                      ? Number(value?.recurringDiscountAmount ?? 0)
                      : 0),
                  0
                )
              : 0;
            return {
              ...res,
              balanceRemark: "",
              discountRemarks: "",
              extraDiscountDocLink: "",
              givingExtraDiscountAmount: 0,
              waivingFine: 0,
              payingFine: 0,
              waivingFineRemark: "",
              checked: true,
              t_paidFeeAmount,
              t_extraDiscountAmount,
              recurringDiscountAmount,
              payingNow:
                Number(res?.feeAmount ?? 0) -
                t_paidFeeAmount -
                t_extraDiscountAmount -
                recurringDiscountAmount,
            };
          }),
          feeObj: success?.data?.obj,
          studentDocId: success?.data?.obj?.studentDetail?.studentDocId,
        }));
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: open ? true : false,
    });
  // get distinct
  const {
    isLoading: getDistinctFeeCollectionLoading,
    isFetching: getDistinctFeeCollectionFetching,
    data: getDistinctFeeCollectionData,
  } = useQuery({
    queryKey: ["getDistinctFeeCollection", state.studentDocId],
    queryFn: () =>
      getDistinctFeeCollection({
        session: selectedSession,
        studentDocId: state.studentDocId,
      }),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: state.studentDocId ? true : false,
  });
  // get fee Collection
  const {
    isLoading: getFeeCollectionLoading,
    isFetching: getFeeCollectionFetching,
    data: getFeeCollectionData,
  } = useQuery({
    queryKey: ["getFeeCollection", state.distinct],
    queryFn: () =>
      getFeeCollection({
        session: selectedSession,
        studentDocId: state.studentDocId,
        feeType: state.distinct,
      }),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: state.distinct ? true : false,
  });
  const { isLoading: getFeeInvoiceLoading, isFetching: getFeeInvoiceFetching } =
    useQuery({
      queryKey: ["getFeeInvoice", state.invoiceNumber],
      queryFn: () => getFeeInvoice({ invoiceNumber: state.invoiceNumber }),
      onSuccess: (success) => {
        state.feeList[state.transactionShowIndex]["feeTrasactions"][
          state.invoiceIndex
        ] = {
          ...state.feeList[state.transactionShowIndex]["feeTrasactions"][
            state.invoiceIndex
          ],
          createdBy: success?.data?.obj?.createdBy,
          createdOn: success?.data?.obj?.createdOn,
          approveddBy: success?.data?.obj?.approveddBy,
          approveddOn: success?.data?.obj?.createdOn,
        };
        setState((prev) => ({
          ...prev,
          invoiceIndex: 0,
          invoiceNumber: "",
        }));
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: state.invoiceNumber ? true : false,
    });
  return (
    <>
      <CustomLoader
        show={
          getFeeInvoiceLoading ||
          getFeeInvoiceFetching ||
          getFeeCollectionLoading ||
          getFeeCollectionFetching
        }
      />
      {/* discount dialog */}
      <DiscountDialog state={state} setState={setState} />
      {/* transaction detail dialog */}
      <Dialog
        open={state.transactionDetail}
        onClose={() =>
          setState((prev) => ({ ...prev, transactionDetail: false }))
        }
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "1000px" } },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Transaction Detail"
            handleClose={() =>
              setState((prev) => ({ ...prev, transactionDetail: false }))
            }
          />
        </DialogTitle>
        <DialogContent sx={{ p: "0 !important" }}>
          <Box sx={{ mt: 1 }} />
          <InvoiceHeading>
            <Grid container spacing={1} p={1} columns={16}>
              <Grid item xs={2}>
                <Typography className="invoice_head__text">
                  Invoice Date
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className="invoice_head__text">
                  Invoice Number
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography className="invoice_head__text">
                  Paid Amount
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="invoice_head__text">
                  Balance Fee
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="invoice_head__text">
                  Balance Remarks
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography className="invoice_head__text">Status</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="invoice_head__text">
                  Created By
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="invoice_head__text">
                  Approved By
                </Typography>
              </Grid>
            </Grid>
          </InvoiceHeading>
          {state?.feeList[state.transactionShowIndex]?.feeTrasactions?.map(
            (item, i) => (
              <Box key={i}>
                <Grid
                  container
                  spacing={1}
                  p={1}
                  columns={16}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Grid item xs={2}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {dayjs(item?.invoiceCreatedOn).format("DD MMM, YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Link
                      to={`/${appRoutes.NewInvoice}/${String(
                        item?.invoiceNumber
                      ).replace(/\//g, " ")}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontWeight: "bold" }}
                    >
                      <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                        {item?.invoiceNumber}
                      </Typography>
                    </Link>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {IndianCurrency(item?.trasactionAmount)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {IndianCurrency(item?.balanceAmount)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {item?.balanceRemark}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {item?.status}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    {item?.createdOn ? (
                      <>
                        <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                          {item?.createdBy?.personName}
                        </Typography>
                        <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                          {dayjs(item?.createdOn).format("DD MMM, YYYY")}
                        </Typography>
                      </>
                    ) : (
                      <IconButton
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            invoiceIndex: i,
                            invoiceNumber: item?.invoiceNumber,
                          }))
                        }
                      >
                        <Refresh sx={{ color: "blue" }} />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {item?.approvedOn ? (
                      <>
                        <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                          {item?.approvedBy?.personName}
                        </Typography>
                        <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                          {dayjs(item?.approvedOn).format("DD MMM, YYYY")}
                        </Typography>
                      </>
                    ) : (
                      <IconButton
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            invoiceIndex: i,
                            invoiceNumber: item?.invoiceNumber,
                          }))
                        }
                      >
                        <Refresh sx={{ color: "blue" }} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Divider
                  sx={{ background: (theme) => theme.palette.primary.main }}
                />
              </Box>
            )
          )}
        </DialogContent>
      </Dialog>
      <CommonDialog
        onClose={handleCloseDialog}
        open={Boolean(open)}
        title="Convert Fee Invoice"
        minWidth="1000px"
      >
        <DialogContent sx={{ px: "10px !important" }}>
          {(getOldInvoiceLoading ||
            getOldInvoiceFetching ||
            getDistinctFeeCollectionLoading ||
            getDistinctFeeCollectionFetching) && (
            <Stack direction="row" justifyContent={"center"}>
              <CircularProgress />
            </Stack>
          )}
          <StyledWrapper>
            <Box className="collection_header__box">
              {/* heading */}
              <Box className="collection_header">
                <Grid
                  container
                  columns={17}
                  spacing={1}
                  sx={{
                    py: "5px",
                    px: "10px",
                    width: "100%",
                  }}
                >
                  <Grid item xs={2}>
                    <Typography className="header_text">Month-Year</Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography className="header_text">Fee Type</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="header_text">Fee Amount</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="header_text">Discount</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="header_text">Paid</Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Typography className="header_text">Paying Now</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography className="header_text">Balance</Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Typography className="header_text">
                      Balance Remark
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              {state.feeList?.map((singleData, i) => (
                <Grid
                  key={i}
                  container
                  columns={17}
                  spacing={1}
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    px: "10px",
                    width: "100%",
                  }}
                >
                  <Grid item xs={2}>
                    <Typography className="item_text">
                      {singleData?.month}-{singleData?.year}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      color="secondary"
                      control={
                        <Checkbox
                          color="secondary"
                          checked={singleData?.checked ? true : false}
                          onClick={() => {
                            if (singleData?.checked) {
                              singleData["checked"] = false;
                            } else {
                              singleData["checked"] = true;
                            }
                            setFalseState(!falseState);
                          }}
                        />
                      }
                      label={singleData?.feeType}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className="item_text">
                      {singleData?.feeAmount ?? "--"}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography
                      className="item_text"
                      sx={{
                        cursor: "pointer",
                        color: "#2789FD !important",
                        display: "inline-block !important",
                      }}
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          discountDialog: true,
                          discountIndex: i,
                        }));
                      }}
                    >
                      {Number(singleData?.t_extraDiscountAmount ?? 0) +
                        Number(singleData?.givingExtraDiscountAmount ?? 0) +
                        Number(singleData?.recurringDiscountAmount ?? 0)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Stack direction="row" gap={0.5}>
                      <Typography
                        className="item_text"
                        sx={{ color: "#29DB5B !important" }}
                      >
                        {singleData?.t_paidFeeAmount ?? 0}
                      </Typography>
                      <InfoOutlined
                        sx={{ cursor: "pointer", opacity: 0.6 }}
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            transactionDetail: true,
                            transactionShowIndex: i,
                          }));
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={2.5}>
                    <input
                      disabled={!singleData?.checked}
                      placeholder="paying amount"
                      type="number"
                      value={singleData?.payingNow}
                      onChange={(e) => {
                        singleData["payingNow"] = e.target.value;
                        singleData["balance"] =
                          Number(singleData?.feeAmount ?? 0) -
                          Number(singleData?.t_extraDiscountAmount ?? 0) -
                          Number(singleData?.givingExtraDiscountAmount ?? 0) -
                          Number(singleData?.t_paidFeeAmount ?? 0) -
                          Number(e.target.value);
                        setState((prev) => ({ ...prev }));
                      }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography className="item_text">
                      {singleData?.balance ?? 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <input
                      disabled={!singleData?.checked}
                      style={{ width: "100%" }}
                      placeholder="balance remark"
                      type="text"
                      value={singleData?.balanceRemark}
                      onChange={(e) => {
                        singleData["balanceRemark"] = e.target.value;
                        setState((prev) => ({ ...prev }));
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
              {/* distict item */}
              <Grid
                container
                columns={17}
                spacing={1}
                display={"flex"}
                alignItems="center"
                sx={{
                  py: "5px",
                  px: "10px",
                  width: "100%",
                }}
              >
                <Grid item xs={2}>
                  <CustomSelectBox
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.distinct}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        distinct: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Distict
                    </MenuItem>
                    {getDistinctFeeCollectionData?.data?.list?.map(
                      (item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      )
                    )}
                  </CustomSelectBox>
                </Grid>

                <Grid item xs={3}>
                  <CustomSelectBox
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.feeCollectionDocId}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        feeCollectionDocId: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Fee
                    </MenuItem>
                    {getFeeCollectionData?.data?.list?.map((item, i) => (
                      <MenuItem value={item?._id} key={i}>
                        {item?.month}-{item?.year}:{item?.feeAmount}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    disabled={!state.feeCollectionDocId}
                    onClick={handleAddExtraFeeCollection}
                  >
                    <CheckCircle color="success" sx={{ fontSize: "24px" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </StyledWrapper>
          {/* summary */}
          <InvoiceSummaryWrapper>
            <Stack direction={"row"} justifyContent={"space-between"} py={1.5}>
              <Box>
                <Typography className="invoice_title">
                  Old Invoice Summary
                </Typography>
                <Stack direction={"row"} gap={1}>
                  <Box>
                    <Typography className="invoice_label">
                      Total Fee Amount
                    </Typography>
                    <Typography className="invoice_value">
                      {IndianCurrency(state.feeObj?.totalAmount)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="invoice_label">
                      Total Discount
                    </Typography>
                    <Typography className="invoice_value">
                      {IndianCurrency(state.feeObj?.discountAmount)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="invoice_label">
                      Total Paid
                    </Typography>
                    <Typography className="invoice_value">
                      {IndianCurrency(state.feeObj?.paidAmount)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="invoice_label">
                      Total Balance
                    </Typography>
                    <Typography className="invoice_value">
                      {IndianCurrency(state.feeObj?.balanceAmount)}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Typography className="invoice_title">
                  New Invoice Summary
                </Typography>
                <Stack direction={"row"} gap={1}>
                  <Box>
                    <Typography className="invoice_label">
                      Total Fee Amount
                    </Typography>
                    <Typography className="invoice_value">
                      {IndianCurrency(calulateTotalAmount())}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="invoice_label">
                      Total Discount
                    </Typography>
                    <Typography className="invoice_value">
                      {IndianCurrency(calulatediscount())}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="invoice_label">
                      Total Paying
                    </Typography>
                    <Typography className="invoice_value">
                      {IndianCurrency(calulateTotalAmount())}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="invoice_label">
                      Total Balance
                    </Typography>
                    <Typography className="invoice_value">
                      {IndianCurrency(calulatebalaceAmount())}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            <Stack direction={"row"} gap={2}>
              <Box>
                <Typography className="invoice_label">
                  Discount Remark
                </Typography>
                <Typography className="invoice_value">
                  {state.feeObj?.discountRemark}
                </Typography>
              </Box>
              <Box>
                <Typography className="invoice_label">
                  Balance Remark
                </Typography>
                <Typography className="invoice_value">
                  {state.feeObj?.balanceRemark}
                </Typography>
              </Box>
            </Stack>
          </InvoiceSummaryWrapper>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="large"
            loading={convertOldInvoiceToNewInvoiceLoading}
            onClick={handleUpdate}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default ConvertInvoiceDialog;
const InvoiceSummaryWrapper = styled(Box)`
  .invoice_title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
  }
  .invoice_label {
    font-size: 13px;
    font-weight: 500;
  }
  .invoice_value {
    font-size: 14px;
    font-weight: 600;
  }
`;
const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  .item_text {
    color: ${THEME.primary};
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .student_detail__wrapper {
    border-radius: 10px;
    border: 1px solid rgba(27, 50, 60, 0.05);
    background: rgba(43, 81, 177, 0.05);
    padding: 10px 20px;
  }

  .collection_header__box {
    border-radius: 10px;
    border: 1px solid rgba(27, 50, 60, 0.05);
    margin-top: 10px;
    .collection_header {
      border-radius: 10px 10px 0 0;
      border: 1px solid rgba(27, 50, 60, 0.05);
      background: rgba(43, 81, 177, 0.05);
    }
    .header_text {
      color: ${THEME.primary};
      font-size: 14px;
      font-weight: 600;
    }

    .collection_array {
      border-radius: 6px;
      background: rgba(43, 81, 177, 0.05);
      border: 1px solid rgba(27, 50, 60, 0.05);
      margin: 10px 10px;

      .check_button__wrap {
        background-color: #fff;
        border-radius: 4px;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: ${THEME.boxShadow1};
      }
      .pay_button {
        border-radius: 10px;
        box-shadow: none;
        text-transform: capitalize;
        font-size: 10px;
      }
      .addToPay_button__success {
        border: 1px solid rgba(92, 197, 9, 0.3);
        background: #fff;
        color: #5fd601;
        letter-spacing: 0.5px;
      }
      .addToPay_button__error {
        border: 1px solid rgba(237, 1, 1, 0.3);
        background: #fff;
        color: rgba(237, 1, 1, 1);
      }
      .addToPay_button__disable {
        border: 1px solid rgba(182, 200, 214, 1);
        background: #fff;
        color: rgba(12, 47, 73, 1);
      }
    }
  }
  .calculation_box {
    border-radius: 10px;
    border: 1px solid rgba(27, 50, 60, 0.05);
    margin-top: 10px;
    padding: 10px;
    .calculation_input__box {
      border-radius: 10px;
      border: 1px solid #b6c8d6;
      background: rgba(43, 81, 177, 0.05);
      height: 40px;
      min-width: 150px;
      display: flex;
      padding: 0 10px;
      align-items: center;
    }
    .calculation_input__box_text {
      font-size: 15px;
      font-weight: 600;

      color: ${THEME.primary};
    }
    .calculation_box__label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 3px;
      color: ${THEME.primary};
    }
    .calculation_box__plusSign {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: -20px;
    }
  }
`;
const InvoiceHeading = styled(Box)`
  background-color: rgba(246, 248, 252, 1);
  .invoice_head__text {
    color: #0c2f49;
    font-size: 14px;
    font-weight: 500;
  }
`;
const ShowDetailBox = styled(Box)`
  border-radius: 15px;
  border: 1px solid #d0d0e2;
  background: rgba(208, 208, 226, 0.08);
  padding: 10px;
`;
