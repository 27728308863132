import { Add, Delete, Edit, YouTube } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
  MenuItem,
  DialogActions,
  styled,
  Chip,
  colors,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { Container } from "@mui/material";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "App";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";

import {
  addRouteDetail,
  deleteTransportRoute,
  getAllRouteList,
  getTransport,
  replaceVehicle,
  updateTransportRoute,
} from "app/services/transport";
import * as yup from "yup";

import CommonTable1 from "app/components/Tables/CommonTable1";
import { useFormik } from "formik";
import DialogHeader from "app/components/common/DialogHeader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import ExportDialog from "app/components/common/exportDialog";
import YoutubeVideoDialog from "app/components/common/Dialogs/YoutubeVideoDialog";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import { Helmet } from "react-helmet";
const TextFieldWrapper = styled(Box)(({ theme }) => ({
  width: "200px",
}));
const validationSchema = yup.object({
  routeNumber: yup
    .number("Please enter only numbers")
    .required("Route number required"),
  routeName: yup
    .string("Please enter alphabets")
    .required("Route name required"),
  // assignedVehicle: yup.array().min(1, "At least on vehicle required"),
  stops: yup.array().of(
    yup.object({
      stopName: yup.string("Only alphabet").required("Required"),
      distance: yup.number("Only numbers").required("Required"),
      hour: yup.mixed().required("required"),
      minutes: yup.mixed().required("Required"),
    })
  ),
});
const RouteDetail = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [vehicleDialog, setVehicleDialog] = useState(false);
  const heightRef = useRef(null);
  const [vehicle, setVehicle] = useState({
    current: "",
    selected: "",
    route: "",
  });
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const [openYoutubeDialog, setOpenYoutubeDialog] = useState(false);
  const [state, setState] = useState({
    deleteRouteDetailDialog: false,
    deleteRouteDocId: "",
  });

  const videoLink = [
    {
      link: "https://www.youtube.com/embed/jXvOzm9zQRM?si=1QH-RzS4XDZJ4N0d",
      title: "How to Add new Route in Transport",
    },
  ];
  const { values, setValues, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        routeDocId: "",
        viewData: {},
        createDialog: false,
        viewDialog: false,
        routeNumber: "",
        routeName: "",
        assinedVehicleNumber: "",
        assinedVehicleType: "",
        assinedVehicleNickName: "",
        edit: false,
        stops: [
          {
            stopName: "",
            distance: "",
            hour: "",
            minutes: "",
          },
        ],
        assignedVehicle: [],
      },
      validationSchema,
      onSubmit: (formValues) => {
        const arr = [];
        formValues.stops.forEach((item) => {
          const hour = Number(item?.hour?.split(" ")[0]) * 60;
          const min = Number(item?.minutes?.split(" ")[0]);
          const duration = Number(hour + min);
          arr.push({
            stopName: item.stopName,
            distance: item?.distance,
            duration: String(duration),
          });
        });

        if (formValues.edit) {
          const body = {
            routeDocId: formValues.routeDocId,
            routeNumber: formValues.routeNumber,
            routeName: formValues.routeName,
            from: "school",
            stopList: arr,
            assignedVehicle: formValues.assignedVehicle,
          };
          updateRouteMutate(body);
        } else {
          const body = {
            routeNumber: formValues.routeNumber,
            routeName: formValues.routeName,
            from: "school",
            stopList: arr,
            assignedVehicle: formValues.assignedVehicle,
          };
          addRouteMutate(body);
        }
      },
    });
  const getError = (name, index) => {
    try {
      if (touched?.stops[index][name] && Boolean(errors?.stops[index][name])) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };
  const getTouched = (name, index) => {
    try {
      if (touched?.stops[index][name]) {
        return errors?.stops[index][name];
      }
    } catch (error) {
      return "";
    }
  };
  let header = [
    "Route_Number",
    "Route_Name",
    "Stop_List",
    "Assigned_Vehicle",
    "From",
  ];

  const handleCloseCreateDialog = () => {
    setValues({
      routeDocId: "",
      viewData: {},
      createDialog: false,
      viewDialog: false,
      routeNumber: "",
      routeName: "",
      assinedVehicleNumber: "",
      assinedVehicleType: "",
      edit: false,
      stops: [
        {
          stopName: "",
          distance: "",
          hour: "",
          minutes: "",
        },
      ],
      assignedVehicle: [],
    });
  };

  // get All Route
  const {
    isLoading: getAllRouteLoading,
    isError: getAllRouteError,
    isSuccess: getAllRouteSuccess,
    data: getAllRouteData,
  } = useQuery({
    queryKey: ["getAllRoute"],
    queryFn: () => getAllRouteList(),
    onSuccess: (isSuccess) => {},
  });
  // get All Transport
  const {
    isLoading: getAllTransportLoading,
    isError: getAllTransportError,
    isSuccess: getAllTransportSuccess,
    data: getAllTransportData,
  } = useQuery({
    queryKey: ["getTransport"],
    queryFn: () => getTransport({ status: "ACTIVE" }),
    onSuccess: (isSuccess) => {},
  });
  // update Route
  const {
    mutate: updateRouteMutate,
    isLoading: updateRouteLoading,
    isError: updateRouteError,
    isSuccess: updateRouteSuccess,
    data: updateRouteData,
    error: updateRouteErrorResponse,
  } = useMutation("updateRoute", updateTransportRoute, {
    onSuccess: (tranportdetaildata) => {
      toast.success(updateRouteData?.data?.message);
      handleCloseCreateDialog();
    },
    onError: (error) => {
      toast.error(updateRouteErrorResponse?.response.data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["getAllRoute"]);
    },
  });
  // add route
  const {
    mutate: addRouteMutate,
    isLoading: addRouteLoading,
    error: addRouteErrorRespose,
  } = useMutation("addRoute", addRouteDetail, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      handleCloseCreateDialog();
    },
    onError: () => {
      toast.error(addRouteErrorRespose?.response?.data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["getAllRoute"]);
    },
  });

  const { mutate: replaceVehicleMutate, isLoading: replaceVehicleLoading } =
    useMutation(replaceVehicle, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        queryClient.invalidateQueries({ queryKey: "getAllRoute" });
        setVehicleDialog(false);
        setVehicle((prev) => ({ route: "", selected: "", current: "" }));
      },
      onError: (err) => toast.error(err?.response?.data?.message),
    });

  const handleAddVehicle = () => {
    let isPresent = false;
    values.assignedVehicle.forEach((item) => {
      if (item?.vehicleNumber === values.assinedVehicleNumber) {
        isPresent = true;
      }
    });
    if (!isPresent && values.assinedVehicleNumber) {
      setValues((prev) => ({
        ...prev,
        assignedVehicle: [
          ...prev.assignedVehicle,
          {
            vehicleNumber: prev.assinedVehicleNumber,
            vehicleType: prev.assinedVehicleType,
            vehicleNickName: prev.assinedVehicleNickName,
          },
        ],
        assinedVehicleNumber: "",
        assinedVehicleType: "",
        assinedVehicleNickName: "",
      }));
    }
  };
  const handleRemoveVehicle = (index) => {
    const filteredData = values.assignedVehicle.filter(
      (item, i) => index !== i
    );
    setValues((prev) => ({
      ...prev,
      assignedVehicle: filteredData,
    }));
  };
  let exportArray = getAllRouteData?.data?.list.map((item) => {
    return {
      Route_Number: item?.routeNumber ?? "--",
      Route_Name: item?.routeName ?? "--",
      Stop_List:
        item?.stopList?.map((list) => list?.stopName).join(",") ?? "--",
      Assigned_Vehicle:
        item?.assignedVehicle?.map((list) => list?.vehicleNumber).join(",") ??
        "--",
      From: item?.from ?? "--",
    };
  });
  const handleAddRoute = () => {
    setValues((prev) => ({
      ...prev,
      stops: [
        ...prev.stops,
        {
          stopName: "",
          distance: "",
          duration: "",
          hour: "",
          minutes: "",
        },
      ],
    }));
  };
  const handleRemoveRoute = (index) => {
    const fiteredData = values.stops?.filter((_, i) => index !== i);
    setValues((prev) => ({
      ...prev,
      stops: fiteredData,
    }));
  };

  const handleEdit = (cell) => {
    const {
      row: { original },
    } = cell;

    const arr = [];
    original?.stopList?.forEach((item) => {
      let hour = "";
      let minutes = "";
      const du = Number(item?.duration);
      if (du > 60) {
        hour = `${Math.trunc(du / 60)} H`;
        minutes = `${Math.trunc(du % 60)} M`;
      } else {
        minutes = `${item?.duration} M`;
        hour = "0 H";
      }
      arr.push({
        stopName: item?.stopName,
        distance: item?.distance,
        hour,
        minutes,
      });
    });
    setValues((prev) => ({
      ...prev,
      routeDocId: original?._id,
      createDialog: true,
      routeNumber: original?.routeNumber,
      routeName: original?.routeName,
      edit: true,
      stops: arr,
      assignedVehicle: original?.assignedVehicle,
    }));
  };

  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>
            {parseInt(cell.row.id) + 1 < 10
              ? `0${parseInt(cell.row.id) + 1}`
              : parseInt(cell.row.id) + 1}
          </Typography>
        ),
        maxSize: isMobile ? 20 : 30,
      },
      {
        header: "Route Number",
        accessorKey: "routeNumber",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{original?.routeNumber}</Typography>,
        size: isMobile ? 30 : 50,
      },
      {
        header: "Route Name",
        accessorKey: "routeName",
        size: isMobile ? 30 : 60,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{original?.routeName}</Typography>,
      },
      {
        header: "Stops",
        accessorKey: "stopList",
        size: isMobile ? 30 : 60,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Stack direction="row" flexWrap="wrap">
              {original?.stopList?.map((item, index) => (
                <Typography>
                  {item?.stopName}
                  {index + 1 < original?.stopList?.length && ` - `}
                </Typography>
              ))}
            </Stack>
          );
        },
      },
      {
        header: "Assigned Vehicle",
        size: isMobile ? 60 : 80,
        accessorKey: "assignedVehicle",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Box>
              {original?.assignedVehicle?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: colors.green[400],
                    py: 0.5,
                    mt: 0.5,
                    borderRadius: "5px",
                    width: isMobile ? "80px" : "120px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setVehicle((prev) => ({
                      ...prev,
                      route: original._id,
                      current: item?.vehicleNumber,
                    }));
                    setVehicleDialog(true);
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#fff",
                      textAlign: "center",
                      fontSize: isMobile ? 10 : 14,
                    }}
                  >
                    {item?.vehicleNumber}
                    <br />
                    {item?.vehicleNickName
                      ? `(${item?.vehicleNickName})`
                      : null}
                  </Typography>
                </Box>
              ))}
            </Box>
          );
        },
      },

      {
        header: "Actions  ",
        Cell: ({ cell }) => {
          return (
            <Stack direction="row" gap={2}>
              {/* <button
                onClick={() => {
                  handleView(cell);
                }}
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "8px",
                  border: "none",
                  outline: "none",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              >
                <Eye />
              </button> */}
              <button
                onClick={() => {
                  handleEdit(cell);
                }}
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "8px",
                  border: "none",
                  outline: "none",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              >
                <Edit />
              </button>
              <button
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    deleteRouteDetailDialog: true,
                    deleteRouteDocId: cell.row.original?._id,
                  }));
                }}
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "8px",
                  border: "none",
                  outline: "none",
                  width: "2.5rem",
                  height: "2.5rem",
                  color: "red",
                }}
              >
                <Delete />
              </button>
            </Stack>
          );
        },
        size: isMobile ? 20 : 40,
      },
    ];
  }, []);

  const { mutate: deleteRouteMutate, isLoading: deleteRouteLoading } =
    useMutation(deleteTransportRoute, {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        handleCloseDeleteRouteDialog();
        queryClient.invalidateQueries({ queryKey: "getAllRoute" });
        setState((prev) => ({
          ...prev,
          deleteRouteDocId: "",
          deleteRouteDetailDialog: false,
        }));
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  const handleCloseDeleteRouteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteRouteDetailDialog: false,
    }));
  };

  const handledeleteRoute = () => {
    deleteRouteMutate(state.deleteRouteDocId);
  };
  return (
    <>
      <Helmet>
        <title>Transport | Route Detail</title>
      </Helmet>
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Route_Detail"
      />

      {/* Delete Dialog Confirmation */}
      <DeleteDialog
        open={state.deleteRouteDetailDialog}
        handleClose={handleCloseDeleteRouteDialog}
        handleDelete={handledeleteRoute}
        loading={deleteRouteLoading}
      />
      {/* create Dialog */}
      <Dialog
        onClose={handleCloseCreateDialog}
        open={values.createDialog}
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "50vw" } },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title={`${values.edit ? "Edit" : "Add"} Route`}
            handleClose={handleCloseCreateDialog}
          />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} id="routeDetaiForm">
            <Grid container spacing={2} sx={{ pt: 1 }}>
              <Grid item xs={6}>
                <TextFieldLabel title="Enter Route Number" />
                <CustomTextField
                  fullWidth
                  size="small"
                  placeholder=" enter Vehicle Number"
                  name="routeNumber"
                  type="number"
                  onChange={handleChange}
                  value={values.routeNumber}
                  error={touched.routeNumber && Boolean(errors.routeNumber)}
                  helperText={touched.routeNumber && errors.routeNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Enter Route Name" />
                <CustomTextField
                  fullWidth
                  size="small"
                  name="routeName"
                  placeholder="route Name"
                  onChange={handleChange}
                  value={values.routeName}
                  error={touched.routeName && Boolean(errors.routeName)}
                  helperText={touched.routeName && errors.routeName}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }} />
            <TextFieldLabel title="Routes" />
            <Grid container spacing={1}>
              {values.stops?.map((item, index) => (
                <>
                  <Grid xs={6} md={3} item>
                    <CustomTextField
                      fullWidth
                      size="small"
                      label="Stop Name"
                      onChange={(e) => {
                        item["stopName"] = e.target.value;
                        setValues((prev) => ({
                          ...prev,
                        }));
                      }}
                      value={item?.stopName}
                      error={getError("stopName", index)}
                      helperText={getTouched("stopName", index)}
                    />
                  </Grid>
                  <Grid xs={6} md={3} item>
                    <CustomTextField
                      fullWidth
                      size="small"
                      label="Distance in KM"
                      type="number"
                      onChange={(e) => {
                        item["distance"] = e.target.value;
                        setValues((prev) => ({
                          ...prev,
                        }));
                      }}
                      value={item?.distance}
                      error={getError("distance", index)}
                      helperText={getTouched("distance", index)}
                    />
                  </Grid>
                  <Grid xs={6} md={2.5} item>
                    <CustomTextField
                      select
                      fullWidth
                      size="small"
                      value={item?.hour}
                      label="Hour"
                      error={getError("hour", index)}
                      helperText={getTouched("hour", index)}
                      SelectProps={{
                        MenuProps,
                      }}
                      onChange={(e) => {
                        item["hour"] = e.target.value;
                        setValues((prev) => ({
                          ...prev,
                        }));
                      }}
                    >
                      {Array.from({ length: 13 }).map((item, index) => (
                        <MenuItem value={`${index} H`} key={index}>
                          {`${index} H`}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                  <Grid xs={6} md={2.5} item>
                    <CustomTextField
                      fullWidth
                      size="small"
                      select
                      label="Minutes"
                      SelectProps={{
                        MenuProps,
                      }}
                      value={item?.minutes}
                      onChange={(e) => {
                        item["minutes"] = e.target.value;
                        setValues((prev) => ({
                          ...prev,
                        }));
                      }}
                      error={getError("minutes", index)}
                      helperText={getTouched("minutes", index)}
                    >
                      <MenuItem value="" disabled>
                        Minutes
                      </MenuItem>
                      {Array.from({ length: 61 }).map((item, index) => (
                        <MenuItem value={`${index} M`} key={index}>
                          {`${index} M`}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                  <Grid xs={1} item>
                    {values.stops.length > 1 && (
                      <Box>
                        <IconButton onClick={() => handleRemoveRoute(index)}>
                          <Delete color="error" />
                        </IconButton>
                      </Box>
                    )}
                  </Grid>
                </>
              ))}
            </Grid>
            <Box sx={{ mt: 1 }}>
              <Button
                onClick={handleAddRoute}
                variant="contained"
                startIcon={<Add />}
                color="secondary"
                sx={{ textTransform: "capitalize" }}
              >
                Add Route
              </Button>
            </Box>

            <Stack direction="row" alignItems={"center"} sx={{ mt: 3 }} gap={2}>
              <TextFieldLabel title="Assign Vehicles" />
              <TextFieldWrapper>
                <CustomSelectBox
                  fullWidth
                  size="small"
                  displayEmpty
                  placeholder="Select"
                  value={values.assinedVehicleNumber}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {getAllTransportData?.data?.list?.map((item, index) => (
                    <MenuItem
                      value={item?.vehicleNumber}
                      key={index}
                      onClick={() => {
                        setValues((prev) => ({
                          ...prev,
                          assinedVehicleNumber: item?.vehicleNumber,
                          assinedVehicleType: item?.vehicleType,
                          assinedVehicleNickName: item?.vehicleNickName,
                        }));
                      }}
                    >
                      {item?.vehicleNumber}{" "}
                      {item?.vehicleNickName
                        ? `(${item?.vehicleNickName})`
                        : "(--)"}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
              </TextFieldWrapper>
              <Box>
                <Button
                  onClick={handleAddVehicle}
                  variant="contained"
                  color="secondary"
                  startIcon={<Add />}
                  sx={{
                    fontSize: isMobile ? "10px" : "14px",
                    textTransform: "capitalize",
                  }}
                >
                  Add Stop
                </Button>
              </Box>
            </Stack>
            {touched.assignedVehicle && Boolean(errors.assignedVehicle) && (
              <Typography color="error">
                {touched.assignedVehicle && errors.assignedVehicle}
              </Typography>
            )}
            <Stack
              direction="row"
              alignItems="center"
              gap={1.5}
              flexWrap="wrap"
              sx={{ mt: 1 }}
            >
              {values.assignedVehicle.length > 0 &&
                values.assignedVehicle?.map((item, index) => (
                  <Chip
                    label={`${item?.vehicleNumber} (${
                      item?.vehicleNickName ?? "--"
                    })`}
                    key={index}
                    onDelete={() => handleRemoveVehicle(index)}
                    sx={{ my: 1 }}
                  />
                ))}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          {values.edit ? (
            <LoadingButton
              form="routeDetaiForm"
              type="submit"
              loading={updateRouteLoading}
              variant="contained"
              color="secondary"
              sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
            >
              Update
            </LoadingButton>
          ) : (
            <LoadingButton
              type="submit"
              form="routeDetaiForm"
              loading={addRouteLoading}
              color="secondary"
              variant="contained"
              sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
            >
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => {
          setVehicleDialog(false);
          setVehicle((prev) => ({ route: "", selected: "", current: "" }));
        }}
        open={vehicleDialog}
        PaperProps={{
          sx: { minWidth: isMobile ? 300 : 500 },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Want to replace ?"
            handleClose={() => {
              setVehicleDialog(false);
              setVehicle((prev) => ({ route: "", selected: "", current: "" }));
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" gap={2} mt={2}>
            <Stack flex={1}>
              <TextFieldLabel title="Current Vehicle" />
              <CustomTextField disabled value={vehicle?.current} />
            </Stack>
            <Stack flex={1}>
              <TextFieldLabel title="New Vehicle" />
              <CustomSelectBox
                MenuProps={MenuProps}
                displayEmpty
                value={vehicle.selected}
                onChange={(e) => {
                  setVehicle((prev) => ({ ...prev, selected: e.target.value }));
                }}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {getAllTransportData?.data?.list?.map((item) => (
                  <MenuItem value={item?.vehicleNumber}>
                    {item?.vehicleNumber} ({item?.vehicleNickName ?? "-"})
                  </MenuItem>
                ))}
              </CustomSelectBox>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={replaceVehicleLoading}
            variant="contained"
            color="secondary"
            onClick={() =>
              replaceVehicleMutate({
                routeDocId: vehicle.route,
                currentVehicleNumber: vehicle.current,
                newVehicleNumbe: vehicle.selected,
              })
            }
          >
            Replace
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <YoutubeVideoDialog
        open={openYoutubeDialog}
        handleCloseDialog={() => setOpenYoutubeDialog(false)}
        videoLink={videoLink}
      />
      <Container
        maxWidth="xl"
        disableGutters={isMobile ? true : false}
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Stack
          mt={isMobile && 2}
          direction="row"
          justifyContent="flex-end"
          gap={2}
        >
          <Button
            onClick={() =>
              setValues((prev) => ({ ...prev, createDialog: true }))
            }
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            sx={{ fontSize: isMobile ? 12 : 16, textTransform: "capitalize" }}
          >
            Add New Route
          </Button>
          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
          <YouTube
            sx={{
              color: "#FF0000",
              fontSize: "2.5rem",
              cursor: "pointer",
              mt: 2,
            }}
            onClick={() => setOpenYoutubeDialog(true)}
          />
        </Stack>

        {/* <Table columns={columns} data={getAllRouteData?.data?.list} /> */}
        <Box sx={{ mt: 2, height: "100%" }} ref={heightRef}>
          <CommonTable1
            columns={columns}
            data={getAllRouteData?.data?.list}
            maxHeight={`${heightRef?.current?.clientHeight - 70}px`}
          />
        </Box>
      </Container>
    </>
  );
};

export default RouteDetail;
