import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { selectSelectedSchoolId } from "../schools/selectors";
import { useMutation, useQuery } from "react-query";
import {
  addYouTubeVideo,
  deleteYouTubeVideo,
  getYouTubeVideo,
} from "app/services/schoolService/websiteBuilder.service";
import QueuePlayNextOutlinedIcon from "@mui/icons-material/QueuePlayNextOutlined";
import { Button, Container, Fab, Grid, Stack, Typography } from "@mui/material";
import DeleteConfirmationDialog from "app/components/common/Dialogs/DeleteConfirmationDialog";
import { toast } from "react-toastify";
import AddYoutubeVideoDialog from "app/components/common/Dialogs/websiteBuilder/AddYoutubeVideoDialog";
import CustomLoader from "app/components/common/CustomLoader";

const YouTubeVideo = () => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const [state, setState] = useState({
    addDialog: false,
    edit: false,
    editData: {},
    deleteDialog: false,
    deleteDocId: "",
  });
  const handleDelete = (docId) => {
    setState((prev) => ({ ...prev, deleteDialog: true, deleteDocId: docId }));
  };
  const handleDeleteConfirmation = () => {
    deleteYouTubeVideoMutate(state.deleteDocId);
  };
  const handleCloseDelete = () => {
    setState((prev) => ({ ...prev, deleteDialog: false, deleteDocId: "" }));
  };
  const {
    mutate: deleteYouTubeVideoMutate,
    isLoading: deleteYouTubeVideoLoading,
  } = useMutation({
    mutationKey: ["deleteYouTubeVideo"],
    mutationFn: deleteYouTubeVideo,
    onSuccess: (success) => {
      handleCloseDelete();
      toast.success(success?.data.message);
      getYouTubeVideoRefetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const { mutate: addYouTubeVideoMutate, isLoading: addYouTubeVideoLoading } =
    useMutation({
      mutationKey: ["addYouTubeVideo"],
      mutationFn: addYouTubeVideo,
      onSuccess: (success) => {
        setState((prev) => ({
          ...prev,
          addDialog: false,
          editData: {},
          edit: false,
        }));
        toast.success(success?.data.message);
        getYouTubeVideoRefetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  const {
    data: getYouTubeVideoData,
    isLoading: getYouTubeVideoLoading,
    isFetching: getYouTubeVideoFetching,
    refetch: getYouTubeVideoRefetch,
  } = useQuery({
    queryKey: ["getYouTubeVideo"],
    queryFn: () =>
      getYouTubeVideo({
        // schoolDocId: "6410053616d5253b29ce5ed9",
        schoolDocId: schoolDocId,
      }),
    onSuccess: (success) => {
      toast.success(success?.data.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      <Helmet>
        <title>Website Builder | You Tube Video</title>
      </Helmet>
      <CustomLoader show={getYouTubeVideoLoading || getYouTubeVideoFetching} />
      <DeleteConfirmationDialog
        open={state.deleteDialog}
        onClose={handleCloseDelete}
        handleDelete={handleDeleteConfirmation}
        loading={deleteYouTubeVideoLoading}
      />

      <AddYoutubeVideoDialog
        open={state.addDialog}
        loading={addYouTubeVideoLoading}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            addDialog: false,
            editData: {},
            edit: false,
          }))
        }
        refetch={() => getYouTubeVideoRefetch()}
        edit={state.editData?.iFrame}
        handleUpdate={(text) => {
          if (state.edit) {
            // update
          } else {
            addYouTubeVideoMutate({
              iFrame: text,
              schoolDocId: schoolDocId,
              // schoolDocId: "6410053616d5253b29ce5ed9",
            });
          }
        }}
      />

      <Container maxWidth="xl">
        <Grid container spacing={4} justifyContent={"center"} display={"flex"}>
          {getYouTubeVideoData?.data?.list?.length === 0 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{ textAlign: "center", margin: 10 }}
              >
                No YouTube Video Found
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      addDialog: true,
                      edit: false,
                      editData: {},
                    }));
                  }}
                >
                  Add Video
                </Button>
              </Stack>
            </Grid>
          )}
          {getYouTubeVideoData?.data?.list?.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <iframe
                width={300}
                height={215}
                src={item?.iFrame}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                //   gap={2}
              >
                {/* <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {}}
              >
                Edit
              </Button> */}
                <Button
                  variant="contained"
                  color="error"
                  size="medium"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => handleDelete(item?._id)}
                >
                  Delete
                </Button>
              </Stack>
            </Grid>
          ))}
        </Grid>

        {getYouTubeVideoData?.data?.list?.length > 0 && (
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: "fixed", bottom: "10%", right: "2%" }}
            onClick={() => {
              setState((prev) => ({
                ...prev,
                addDialog: true,
                edit: false,
                editData: {},
              }));
            }}
          >
            <QueuePlayNextOutlinedIcon style={{ height: 100, width: 30 }} />
          </Fab>
        )}
      </Container>
    </>
  );
};

export default YouTubeVideo;
