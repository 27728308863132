import appRoutes from "app/config/routes";
import React from "react";
import { Navigate } from "react-router-dom";
import Fallback from "app/components/Fallback";

const SessionSettings = React.lazy(() =>
  import("app/modules/school-management/pages/SessionSetting")
);

const Departments = React.lazy(() =>
  import("app/modules/school-management/pages/Departments")
);

const Classes = React.lazy(() =>
  import("app/modules/school-management/pages/Classes")
);

const AccessControl = React.lazy(() =>
  import("app/modules/school-management/pages/AccessControl")
);
const InvitationCode = React.lazy(() =>
  import("app/modules/school-management/pages/InvitationCode")
);

const Storage = React.lazy(() =>
  import("app/modules/school-management/pages/storage")
);

export const SessionSettingsPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SessionSettings {...props} />
  </React.Suspense>
);
export const InvitationCodePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <InvitationCode {...props} />
  </React.Suspense>
);

export const DepartmentsPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Departments {...props} />
  </React.Suspense>
);

export const ClassesPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Classes {...props} />
  </React.Suspense>
);

export const AccessControlPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <AccessControl {...props} />
  </React.Suspense>
);

export const StoragePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Storage {...props} />
  </React.Suspense>
);

const SchoolManagementIndex = () => {
  return <Navigate to={{ pathname: `/${appRoutes.sessionSettings}` }} />;
};

export default SchoolManagementIndex;
