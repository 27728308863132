import { Eye } from "react-bootstrap-icons";
import { useMutation, useQuery } from "react-query";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet";
import "react-notifications/lib/notifications.css";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

//imports of our files
import {
  getAllClassDropdowns,
  getAllDuesTillDate,
  getFeeCollectionByStudent,
} from "app/services/management";
import {
  selectedMediumSelector,
  selectActiveSession,
  selectSelectedSchoolId,
} from "app/modules/schools/selectors";

import FeeCollectionModal from "./FeeCollectionModal";
import { getExtraFeeStructureDropdown } from "./thunk";

import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import CommonTable1 from "app/components/Tables/CommonTable1";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";
import DialogHeader from "app/components/common/DialogHeader";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import { Container } from "@mui/system";
import TableProfilePic from "app/components/common/TableProfilePic";
import StudentSearchInput from "app/components/common/StudentSearchInput";
import TextFieldLabel from "app/components/common/TextFieldLabel";
export default function FeeCollectionMain() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const session = useSelector(selectActiveSession);
  const medium = useSelector(selectedMediumSelector);
  //getting the schooldocid using selector
  const schoolDocId = useSelector(selectSelectedSchoolId);

  const [mainData, setMainData] = useState([]);
  const [unpaidData, setUnpaidData] = useState({
    totalUnPaid: 0,
    totalDues: 0,
  });
  const [classOptions, setClassOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [filterClassOption, setFilterClassOption] = useState(
    "By Class and Due Date"
  );
  const [classStreamObj, setClassStreamObj] = useState([]);
  const [section, setSection] = useState("");
  const [show, setShow] = useState(false);
  const [viewActionModal, setViewActionModal] = useState(false);
  const [streamValue, setStreamValue] = useState("");
  const [disableBtn, setDisablebtn] = useState(true);
  const [studentFeeDetails, setStudentFeeDetails] = useState(null);
  const [dueDate, setDueDate] = useState(new Date());
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [admissionNumber, setAdmissionNumber] = useState("");
  let header = [
    "Session",
    "ClassName_stream",
    "RegistrationNumber",
    "StudentName",
    "FatherName",
    "MobileNumber",

    "LastYearArrear",
    "TotalFee",
    "TotalDiscount",
    "TotalPaid",
    "TotalBalanceFee",
    "TotalDues",
    "Dues_Month",
  ];
  const [selectedOptions, setSelectedOptions] = useState(header);
  const [openCheckBox, setOpenCheckBox] = useState(false);

  const [state, setState] = useState({
    cell: {},
    fetch: false,
    nameSearchDialog: "",
  });
  const handleSubmitNameSearch = (list) => {
    getStudentsListLogicMutate({
      reqStudentDocIdList: JSON.stringify(list),
      session: session,
      date: dueDate,
    });
  };
  //react query for getting the table data
  const {
    mutate: getStudentsListLogicMutate,
    isLoading: getStudentsListLogicMutateLoading,
  } = useMutation(getAllDuesTillDate, {
    onSuccess: (data) => {
      setMainData(data?.data?.list);
      setUnpaidData({
        totalUnPaid: data?.data?.totalUnPaid,
        totalDues: data?.data?.totalDues,
      });
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const {
    isLoading: feesDataLoading,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["getAllDuesTillDate", session],
    queryFn: () => queryFuncition(),
    enabled: false,
    onSuccess: (data) => {
      setMainData(data?.data?.list);
      setUnpaidData({
        totalUnPaid: data?.data?.totalUnPaid,
        totalDues: data?.data?.totalDues,
      });
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const queryFuncition = () => {
    let params;
    if (filterClassOption === "By Class and Due Date") {
      params = {
        classDocId: streamValue,
        section: section,
        session: session,
        date: dueDate,
      };
    }
    if (filterClassOption === "By Registration Number") {
      params = {
        registrationNumber: registrationNumber,
        session: session,
        date: dueDate,
      };
    }
    if (filterClassOption === "By Admission Number") {
      params = {
        admissionNumber: admissionNumber,
        session: session,
        date: dueDate,
      };
    }

    return getAllDuesTillDate(params);
  };
  const dispatch = useDispatch();

  //function for getting the fee structure on click of action button
  const fetchFeeCollectionByRegNo = async (studentDocId) => {
    const res = await getFeeCollectionByStudent(studentDocId, session);
    const temp = res.data;
    setStudentFeeDetails(temp);
  };

  //getting the all class dropdown
  useEffect(() => {
    const fetchClassData = async () => {
      const res = await getAllClassDropdowns(schoolDocId, session, medium);
      const data = res.data;
      setClassOptions(data?.list);
    };

    fetchClassData();
  }, [session, medium]);

  //logic for the disable section dropdown
  useEffect(() => {
    const sectionArray = classOptions.filter((item) => {
      return item.classDocId === streamValue;
    });

    setSectionOptions(sectionArray[0]?.sections);
    if (streamValue.length > 0) setDisablebtn(false);
  }, [streamValue, classOptions]);

  //function for getting the fee structure on click of action button

  //this is the columns used by mui table
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => <p>{parseInt(cell.row.id) + 1}</p>,
        size: 20,
      },
      {
        header: "Reg No.",
        accessorKey: "registrationNumber",
        size: 20,
      },
      {
        header: "Student Name",
        accessorKey: "studentName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <>
              <Stack direction="row" alignItems="center" gap={0.6}>
                <TableProfilePic image={original?.profilePic} />
                <Link
                  to={`/${appRoutes.student_profile}/${original?.studentDocId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography>{original?.studentName}</Typography>
                </Link>
              </Stack>
            </>
          );
        },
        size: 80,
      },
      {
        header: "Father Name",
        accessorKey: "fatherName",
        size: 40,
      },
      {
        header: "Last Year Arrear",
        accessorKey: "lastYearArrear",
        size: 20,
      },
      {
        header: "Total Fee",
        accessorKey: "totalFee",
        size: 20,
      },
      {
        header: "Total Paid",
        accessorKey: "totalPaid",
        size: 20,
      },
      {
        header: "Total Unpaid",
        accessorKey: "totalBalanceFee",
        size: 20,
      },
      {
        header: "Total Dues",
        accessorKey: "totalDues",
        size: 20,
      },
      {
        header: "Dues Month",
        accessorKey: "months",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.months?.map((item) => item).join(", ")}
          </Typography>
        ),
        maxSize: 70,
      },
      {
        header: "Actions",
        Cell: ({ cell }) => {
          return (
            <button
              onClick={() => {
                handleViewAction(cell);
              }}
              style={{
                backgroundColor: "#EBF0F4",
                borderRadius: "8px",
                border: "none",
                outline: "none",
                width: "2.5rem",
                height: "2.5rem",
              }}
            >
              <Eye />
            </button>
          );
        },
        size: 20,
      },
    ];
  }, []);

  //handle function for onClick of the action button
  const CallStudentFeeCollection = () => {
    dispatch(
      getExtraFeeStructureDropdown({
        session: session,
        medium: medium,
        classDocId: state.cell?.row?.original?.classDocId,
        section: state.cell?.row?.original?.section,
      })
    );
    fetchFeeCollectionByRegNo(state.cell?.row?.original?.studentDocId);
  };
  const handleViewAction = (cell) => {
    setShow(true);
    setState((prev) => ({ ...prev, cell, fetch: true }));
  };
  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  //function for handling the export

  const handleExport = () => {
    const filteredExportArray = exportArray.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Fee Collection");
    XLSX.writeFile(workbook, "feeCollection.xlsx");
  };

  //function for getting the combined class stream and section

  const getClassStreamObj = (classOptions) => {
    let tempArray = [];
    classOptions?.map((obj) => {
      obj.sections?.map((section) => {
        tempArray.push({
          classStreamSection: `${obj?.className_stream}-${
            section ? section : ""
          }`,
          classDocId: obj?.classDocId,
          section: section ? section : "",
        });
      });
    });

    setClassStreamObj(tempArray);
  };

  //calling that function every time when the data changes from the api
  useEffect(() => {
    getClassStreamObj(classOptions);
  }, [classOptions]);

  //handler function for the class stream section filter
  const handleChange = (e) => {
    setFilterClassOption(e.target?.value);
  };
  const handleInvoiceDetails = (invoiceId) => {};
  // Use Query to fetch Invoice Details
  useEffect(() => {
    if (state.fetch) {
      CallStudentFeeCollection();
      setState((prev) => ({ ...prev, fetch: false }));
    }
  }, [state.fetch]);
  useEffect(() => {
    if (!show) {
      setStudentFeeDetails(null);
    }
  }, [show]);

  let exportArray = mainData?.map((item) => {
    return {
      Session: item?.session,
      ClassName_stream: `${item?.className_stream} ${item?.section}`,
      RegistrationNumber: item?.registrationNumber,
      StudentName: item?.studentName,
      FatherName: item?.fatherName,
      MobileNumber: item?.mobileNumber,
      LastYearArrear: item?.lastYearArrear,
      TotalFee: item?.totalFee,
      TotalDiscount: item?.totalDiscount,
      TotalPaid: item?.totalPaid,
      TotalBalanceFee: item?.totalBalanceFee,
      TotalDues: item?.totalDues,
      Dues_Month: item?.months?.map((item) => item)?.join(", "),
    };
  });

  return (
    <>
      <Helmet>
        <title>Fee | Fee Collection</title>
      </Helmet>
      {/* Dialog for selecting options for export*/}
      <Dialog
        open={openCheckBox}
        onClose={() => setOpenCheckBox(false)}
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to show in the excel file"
          handleClose={() => setOpenCheckBox(false)}
        />
        <DialogContent>
          <Box>
            {header.map((option) => (
              <Stack direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChange(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptions(header);
              handleExport();
              setOpenCheckBox(false);
            }}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>

      {/* the add new fee structure modal */}

      <FeeCollectionModal
        show={show}
        setShow={setShow}
        fetchFeeCollectionByRegNo={() =>
          setState((prev) => ({ ...prev, fetch: true }))
        }
        studentFeeDetails={studentFeeDetails}
        handleInvoiceDetails={handleInvoiceDetails}
      />
      <Container maxWidth="xl" disableGutters={isMobile ? true : false}>
        {/* div for the top 2 buttons */}
        <Stack direction={"row"} gap={1}>
          <Box>
            <Typography variant="h5">Filter Students</Typography>
            <CustomSelectBox
              sx={{ width: { xs: "80vw", sm: "20vw" } }}
              className="select_box"
              size="small"
              id="classFilter"
              value={filterClassOption}
              fullWidth
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <MenuItem value="By Class and Due Date">
                By Class and Due Date
              </MenuItem>
              <MenuItem value="By Registration Number">
                By Registration Number
              </MenuItem>
              <MenuItem value="By Admission Number">
                By Admission Number
              </MenuItem>
              <MenuItem value="By Name">By Name</MenuItem>
            </CustomSelectBox>
          </Box>
          <Box sx={{ width: { xs: "30vw", sm: "10vw" } }}>
            <TextFieldLabel title={"Due Date"} />
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={dueDate}
              onChange={(newValue) => {
                setDueDate(newValue);
              }}
              renderInput={(params) => <CustomTextField {...params} />}
            />
          </Box>
        </Stack>

        {/* filter section div  */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={{ xs: 1, sm: 2 }}
            sx={{ pt: 2 }}
          >
            {filterClassOption === "By Name" && (
              <StudentSearchInput
                loading={getStudentsListLogicMutateLoading}
                handleSearch={handleSubmitNameSearch}
              />
            )}
            {filterClassOption === "By Class and Due Date" && (
              <>
                <Box sx={{ width: { xs: "30vw", sm: "15vw" } }}>
                  <CustomTextField
                    select
                    SelectProps={{
                      MenuProps: MenuProps,
                    }}
                    size="small"
                    fullWidth
                    value={streamValue}
                    onChange={(e) => {
                      setStreamValue(e.target?.value);
                    }}
                    label="Class"
                  >
                    {classOptions &&
                      classOptions?.map((opt, i) => {
                        return (
                          <MenuItem
                            key={opt?.classDocId}
                            value={opt?.classDocId}
                            name={opt?.className_stream}
                          >
                            {opt?.className_stream}
                          </MenuItem>
                        );
                      })}
                  </CustomTextField>
                </Box>
                <Box sx={{ width: { xs: "20vw", sm: "10vw" } }}>
                  <CustomTextField
                    select
                    fullWidth
                    size="small"
                    value={section}
                    onChange={(e) => setSection(e.target.value)}
                    label="Section"
                    disabled={disableBtn}
                  >
                    <MenuItem value="allSection">All Section</MenuItem>
                    {sectionOptions &&
                      sectionOptions?.map((opt, i) => {
                        return (
                          <MenuItem key={i} value={opt}>
                            {opt}
                          </MenuItem>
                        );
                      })}
                  </CustomTextField>
                </Box>
                <LoadingButton
                  disabled={streamValue && section ? false : true}
                  color="secondary"
                  size="large"
                  loading={feesDataLoading || isFetching}
                  variant="contained"
                  onClick={refetch}
                >
                  View
                </LoadingButton>
              </>
            )}
            {filterClassOption === "By Registration Number" && (
              <>
                <Box sx={{ width: { xs: "45vw", sm: "15vw" } }}>
                  <CustomTextField
                    size="small"
                    fullWidth
                    value={registrationNumber}
                    onChange={(e) => {
                      setRegistrationNumber(e.target?.value);
                    }}
                    label="Registration Number"
                  />
                </Box>
                <LoadingButton
                  disabled={registrationNumber ? false : true}
                  color="secondary"
                  size="large"
                  loading={feesDataLoading || isFetching}
                  variant="contained"
                  onClick={refetch}
                >
                  View
                </LoadingButton>
              </>
            )}
            {filterClassOption === "By Admission Number" && (
              <>
                <Box sx={{ width: { xs: "45vw", sm: "15vw" } }}>
                  <CustomTextField
                    size="small"
                    fullWidth
                    value={admissionNumber}
                    onChange={(e) => {
                      setAdmissionNumber(e.target?.value);
                    }}
                    label="Admission Number"
                  />
                </Box>
                <LoadingButton
                  disabled={admissionNumber ? false : true}
                  color="secondary"
                  size="large"
                  loading={feesDataLoading || isFetching}
                  variant="contained"
                  onClick={refetch}
                >
                  View
                </LoadingButton>
              </>
            )}
          </Stack>
          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
        </Stack>
        {/* total dues */}
        <Stack direction="row" alignItems={"center"} gap={2} pt={2}>
          <Stack direction="row" alignItems={"center"} gap={0.5}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, opacity: 0.75 }}
            >
              Total Un-Paid:
            </Typography>
            <Typography
              style={{ fontWeight: 600, opacity: 1, fontSize: "15px" }}
            >
              {IndianCurrency(unpaidData.totalUnPaid)}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems={"center"} gap={0.5}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, opacity: 0.75 }}
            >
              Total Dues ToDate:
            </Typography>
            <Typography
              style={{ fontWeight: 600, opacity: 1, fontSize: "15px" }}
            >
              {IndianCurrency(unpaidData.totalDues)}
            </Typography>
          </Stack>
        </Stack>
        {/* table section  */}
        <Box sx={{ mt: 1 }}>
          <CommonTable1
            columns={columns}
            data={mainData}
            setViewActionModal={setViewActionModal}
            maxHeight="67vh"
          />
        </Box>
      </Container>
    </>
  );
}
