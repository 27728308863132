import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";
import { Add, Delete, Search } from "@mui/icons-material";
import { useQuery } from "react-query";
import {
  getFeeRelaxationCategory,
  getFeeRelaxationCategoryByStudent,
} from "app/services/schoolService/newFee.service";
import CustomLoader from "app/components/common/CustomLoader";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchool,
} from "app/modules/schools/selectors";
import { getStudents1 } from "app/services/management";
import { getStaffDetailByEmpoyeeId } from "app/services/hr";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import { FormatFullName, MenuProps } from "app/utils/helper";
import { useParams } from "react-router-dom";
import useFolderWiseFileUploader from "app/hooks/upload/useFolderWiseFileUploader";
const Siblings = ({ item, setState, state, getClassDropdownData, i }) => {
  const selectedSchool = useSelector(selectSelectedSchool);
  const selectedSession = useSelector(selectActiveSession);
  const { data: getStudents1Data } = useQuery({
    queryKey: ["getStudents1", item.classDocId],
    queryFn: () =>
      getStudents1({
        classDocId: item.classDocId,
        session: selectedSession,
      }),
    onSuccess: (data) => {},
    enabled: item.classDocId ? true : false,
  });
  return (
    <Grid container py={1} spacing={1} display={"flex"} alignItems={"center"}>
      <Grid item xs={12} md={3}>
        <TextFieldLabel title={"School Name"} required />
        <CustomTextFieldDisabled
          native
          displayEmpty
          size="small"
          fullWidth
          value={selectedSchool?.schoolName}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextFieldLabel title={"Class Info"} required />
        <CustomSelectBox
          native
          displayEmpty
          size="small"
          fullWidth
          value={item?.classDocId}
          onChange={(e) => {
            item.classDocId = e.target.value;
            item.studentDocId = "";
            setState((prev) => ({ ...prev }));
          }}
        >
          <MenuItem value="" disabled component="option">
            Select
          </MenuItem>
          {getClassDropdownData?.data?.list?.map((res, i) => (
            <MenuItem component="option" value={res?.classDocId}>
              {res?.className_stream}
            </MenuItem>
          ))}
        </CustomSelectBox>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextFieldLabel title={"Student Name"} required />
        <CustomSelectBox
          displayEmpty
          size="small"
          fullWidth
          value={item.studentDocId}
          onChange={(e) => {
            item.studentDocId = e.target.value;
            setState((prev) => ({ ...prev }));
          }}
          MenuProps={MenuProps}
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {getStudents1Data?.data?.list?.map((res, ind) => (
            <MenuItem value={res?._id} key={ind}>
              {res?.firstName} {res?.middleName && ` ${res?.middleName}`}
              {res?.lastName && ` ${res?.lastName}`}
            </MenuItem>
          ))}
        </CustomSelectBox>
      </Grid>
      <Grid item xs={12} md={1}>
        <Box sx={{ mt: 3 }}>
          {i + 1 === state.siblingDetails?.length ? (
            <Stack direction={"row"} gap={1}>
              {i > 0 && (
                <IconButton
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      siblingDetails: prev.siblingDetails.filter(
                        (_, ind) => ind !== i
                      ),
                    }))
                  }
                >
                  <Delete color="error" />
                </IconButton>
              )}
              <IconButton
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    siblingDetails: [
                      ...prev.siblingDetails,
                      {
                        schoolDocId: "",
                        studentDocId: "",
                        classDocId: "",
                      },
                    ],
                  }))
                }
              >
                <Add color="success" sx={{ fontSize: "20px" }} />
              </IconButton>
            </Stack>
          ) : (
            <IconButton
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  siblingDetails: prev.siblingDetails.filter(
                    (_, ind) => ind !== i
                  ),
                }))
              }
            >
              <Delete color="error" />
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
const EditFeeRelaxationDialog = ({
  state,
  setState,
  getClassDropdownData,
  externalSubmit,
  handleExternalSubmit,
}) => {
  const [empoyeeId, setEmpoyeeId] = useState("");
  const params = useParams();
  console.log(params, "in params");
  const selectedSession = useSelector(selectActiveSession);
  const selectedSchool = useSelector(selectSelectedSchool);
  const [loading, setLoading] = useState(false);
  const { handleUploadImage } = useFolderWiseFileUploader();
  const handleUploadCertificate = async (e) => {
    setLoading(true);
    try {
      let attachmentUrl = await handleUploadImage(
        false,
        e,
        "withoutSession",
        "student-relaxation-category-doc",
        "certificate"
      );
      setState((prev) => ({
        ...prev,
        certificateUrl: attachmentUrl.fileURL,
      }));
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  const handleCloseFeeRelaxationCategoryDialog = () => {
    setLoading(false);
    setState((prev) => ({
      ...prev,
      feeRelaxationCategoryDialog: false,
    }));
  };
  const {
    isLoading: getFeeRelaxationCategoryLoading,
    isFetching: getFeeRelaxationCategoryFetching,
    data: getFeeRelaxationCategoryData,
  } = useQuery({
    queryKey: ["getFeeRelaxationCategory"],
    queryFn: () => getFeeRelaxationCategory(),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: state.feeRelaxationCategoryDialog ? true : false,
  });
  const {
    isLoading: getFeeRelaxationCategoryByStudentLoading,
    isFetching: getFeeRelaxationCategoryByStudentFetching,
    data: getFeeRelaxationCategoryByStudentData,
  } = useQuery({
    queryKey: [
      "getFeeRelaxationCategoryByStudent",
      state.feeRelaxationCategoryDialog,
    ],
    queryFn: () =>
      getFeeRelaxationCategoryByStudent({
        studentDocId: params?.studentDocId,
        session: selectedSession,
      }),
    onSuccess: (success) => {
      switch (success?.data?.relaxationName) {
        case "Siblings":
          setState((prev) => ({
            ...prev,
            siblingDetails: success?.data?.studentList?.map((res) => ({
              ...res,
              studentDocId: res?._id,
            })),
          }));
          break;
        case "Staff_Ward":
          setState((prev) => ({
            ...prev,
            empoyeeName: FormatFullName(
              success?.data?.staffObj?.firstName,
              success?.data?.staffObj?.middleName,
              success?.data?.staffObj?.lastName
            ),
            staffDocId: success?.data?.staffObj?._id,
          }));
          break;

        default:
          break;
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: state.feeRelaxationCategoryDialog ? true : false,
  });
  const {
    isLoading: getEmployeeInfoLoading,
    isFetching: getEmployeeInfoFetching,
    refetch: getEmployeeInfoRefetch,
  } = useQuery({
    queryKey: ["getEmployeeInfo", empoyeeId],
    queryFn: () => getStaffDetailByEmpoyeeId({ employeeId: empoyeeId }),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        employeeId: success?.data?.obj?._id,
        empoyeeName: `${success?.data?.obj?.firstName} ${success?.data?.obj?.middleName} ${success?.data?.obj?.lastName}`,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: false,
  });
  return (
    <>
      <CustomLoader
        show={
          getFeeRelaxationCategoryLoading || getFeeRelaxationCategoryFetching
        }
      />
      {/* fee relaxation dialog */}
      <CommonDialog
        PaperProps={{ sx: { minWidth: "700px" } }}
        open={state.feeRelaxationCategoryDialog}
        onClose={handleCloseFeeRelaxationCategoryDialog}
        minWidth="700px"
        title="Fee Relaxation Category"
      >
        <DialogContent>
          <Stack direction={"row"} columnGap={1.5} pb={2} flexWrap={"wrap"}>
            {getFeeRelaxationCategoryData?.data?.list?.map((item, i) => (
              <FormControlLabel
                control={
                  <Radio
                    color="secondary"
                    checked={
                      state.feeRelaxationCategory ===
                      item?.feeRelaxationCategory
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        feeRelaxationCategory: item?.feeRelaxationCategory,
                      }))
                    }
                  />
                }
                label={item?.feeRelaxationCategory}
              />
            ))}
          </Stack>
          {state.feeRelaxationCategory === "Siblings" && (
            <>
              {state.siblingDetails?.map((item, i) => (
                <Siblings
                  key={i}
                  item={item}
                  getClassDropdownData={getClassDropdownData}
                  i={i}
                  state={state}
                  setState={setState}
                />
              ))}
            </>
          )}
          {!["General", "Siblings", "Staff_Ward"].includes(
            state.feeRelaxationCategory
          ) && (
            <>
              <Stack direction={"row"} gap={2} alignItems={"flex-end"}>
                <Box sx={{ width: "200px" }}>
                  <TextFieldLabel title={"Certificate Name"} required />
                  <CustomTextField
                    size="small"
                    fullWidth
                    value={state.certificateName}
                    placeholder="Type name"
                    name="certificateName"
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        certificateName: e.target.value,
                      }))
                    }
                  />
                </Box>
                <Box>
                  <TextFieldLabel required title={"Attachment"} />
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <LoadingButton
                      loading={loading}
                      component="label"
                      variant="outlined"
                      size="large"
                      color="secondary"
                      sx={{ borderRadius: "10px", textTransform: "capitalize" }}
                    >
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handleUploadCertificate(e)}
                        accept="image/*,application/pdf"
                      />
                      Upload
                    </LoadingButton>
                    {state.certificateUrl && (
                      <Typography>
                        {
                          String(state.certificateUrl).split(
                            "student-relaxation-category-doc/"
                          )[1]
                        }
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </Stack>
            </>
          )}
          {state.feeRelaxationCategory === "Staff_Ward" && (
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"School Name"} required />
                <CustomTextFieldDisabled
                  native
                  displayEmpty
                  size="small"
                  fullWidth
                  value={selectedSchool?.schoolName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Employee ID"} required />
                <CustomTextField
                  placeholder="Type employe id"
                  displayEmpty
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {getEmployeeInfoLoading || getEmployeeInfoFetching ? (
                          <CircularProgress size={25} />
                        ) : (
                          <Search
                            onClick={() => getEmployeeInfoRefetch()}
                            sx={{ cursor: "pointer" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  value={empoyeeId}
                  onChange={(e) => setEmpoyeeId(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Staff Name"} required />
                <Typography sx={{ fontWeight: 600, mt: 1.5 }}>
                  {state.empoyeeName}
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="error"
            onClick={() => handleCloseFeeRelaxationCategoryDialog()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => {
              externalSubmit
                ? handleExternalSubmit()
                : setState((prev) => ({
                    ...prev,
                    feeRelaxationCategoryDialog: false,
                  }));
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default EditFeeRelaxationDialog;
