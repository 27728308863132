import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
  styled,
} from "@mui/material";
import CustomLoader from "app/components/common/CustomLoader";

import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { queryClient } from "App";
import DialogHeader from "app/components/common/DialogHeader";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";
import { MenuProps } from "app/utils/helper";
import { States } from "app/config/administrativeConstants";
import { Distrct } from "app/config/administrativeConstants";
import {
  getAllFirm,
  updateFirmProfile,
} from "app/services/schoolService/firm.service";
import useFolderWiseFileUploader from "app/hooks/upload/useFolderWiseFileUploader";

const FirmProfile = () => {
  const [data, setData] = useState(null);

  const [dialogs, setDialogs] = useState({
    update: false,
  });

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({});

  const { id } = useParams();

  const { isLoading, isFetching } = useQuery({
    queryKey: "getFirmData",
    queryFn: () => getAllFirm({ firmDocId: id }),
    onSuccess: (res) => {
      setData(res?.data?.obj);
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.data?.response?.message);
    },
  });

  const { mutate: updateFirmMutate, loading: updateFirmLoading } = useMutation(
    updateFirmProfile,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        queryClient.invalidateQueries({ queryKey: "getFirmData" });
        setDialogs((prev) => ({ ...prev, update: false }));
        updateFormik.resetForm();
        setState((prev) => ({
          ...prev,
          image: { file: "", link: "" },
          pic: { file: "", link: "" },
        }));
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    }
  );

  const updateFormik = useFormik({
    initialValues: {
      id: "",
      fName: "",
      cat: "",
      first: "",
      middle: "",
      last: "",
      number: "",
      email: "",
      address: "",
      state: "",
      dist: "",
      pin: "",
      desc: "",
      profilePic: "",
      firmPic: "",
    },
    validationSchema: yup.object().shape({
      fName: yup.string().required("Firm name is required"),
      cat: yup.string().required("Category is required"),
      first: yup.string().required("First name is required"),
      middle: yup.string().optional(),
      last: yup.string().required("Last name is required"),
      number: yup
        .string()
        .required("Phone number is required")
        .matches(/^[0-9]{10}$/, "Invalid phone number"),
      // email: yup.string().email('Invalid email').required('Email is required'),
      address: yup.string().required("Address is required"),
      state: yup.string().required("State is required"),
      dist: yup.string().required("District is required"),
      pin: yup
        .string()
        .required("PIN code is required")
        .matches(/^[0-9]{6}$/, "Invalid PIN code"),
      desc: yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      handleUpdateUploadServer(values);
      // const obj = {
      //     firmName: values.fName,
      //     businessCategory: values.cat,
      //     firstName: values.first,
      //     middleName: values.middle,
      //     lastName: values.last,
      //     profilePic: "",
      //     mobileNumber: values.number,
      //     email: values.email,
      //     description: values.desc
      // }
      // addFirmMutate(obj)
    },
  });

  const { handleUploadImage } = useFolderWiseFileUploader();
  const handleUpdateUploadServer = async (values) => {
    setLoading(true);
    const obj = {
      firmDocId: values.id,
      firmName: values.fName,
      businessCategory: values.cat,
      firstName: values.first,
      middleName: values.middle,
      lastName: values.last,
      profilePic: values?.profilePic,
      firmPic: values?.firmPic,
      mobileNumber: String(values.number),
      email: String(values.email).trim().toLowerCase(),
      description: values.desc,
      firmAddress: {
        state: values?.state,
        address: values?.address,
        dist: values?.dist,
        pinCode: values?.pin,
      },
    };
    try {
      if (state?.image?.file) {
        let attachmentUrl = await handleUploadImage(
          true,
          state?.image?.file,
          "withoutSession",
          "firm",
          "firmProfilePic"
        );

        obj["profilePic"] = attachmentUrl.fileURL;
      }
      if (state?.pic?.file) {
        let attachmentUrl = await handleUploadImage(
          true,
          state?.pic?.file,
          "withoutSession",
          "firm",
          "firmPic"
        );
        obj["firmPic"] = attachmentUrl.fileURL;
      }
      updateFirmMutate(obj);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const handleUpload = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    let fileSize = file.size;

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (!allowedTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }

    if (fileSize > 5 * 1000000) {
      // fileSize > 5MB then show popup message
      toast.error(
        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      image: {
        file: e.target.files[0],
        link: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };

  const handleFirmPicUpload = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    let fileSize = file.size;

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (!allowedTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }

    if (fileSize > 5 * 1000000) {
      // fileSize > 5MB then show popup message
      toast.error(
        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      pic: {
        file: e.target.files[0],
        link: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };

  const districts = Distrct[States.indexOf(updateFormik.values.state) + 1];

  return (
    <>
      <CustomLoader show={isLoading || isFetching} />
      <Container maxWidth="xl">
        <Dialog
          open={dialogs?.update}
          onClose={() => {
            setDialogs((prev) => ({ ...prev, update: false }));
            updateFormik.resetForm();
            setState((prev) => ({
              ...prev,
              image: { file: "", link: "" },
              pic: { file: "", link: "" },
            }));
          }}
          PaperProps={{
            sx: { minWidth: { xs: "95%", md: 500, lg: 700 } },
          }}
        >
          <DialogHeader
            title="Update New Firm"
            handleClose={() => {
              setDialogs((prev) => ({ ...prev, update: false }));
              updateFormik.resetForm();
              setState((prev) => ({
                ...prev,
                image: { file: "", link: "" },
                pic: { file: "", link: "" },
              }));
            }}
          />
          <form onSubmit={updateFormik.handleSubmit} id="form">
            <DialogContent>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Stack flex={2}>
                    <Label>Firm's Name</Label>
                    <CustomTextField
                      fullWidth
                      size="small"
                      sx={{ marginTop: "8px" }}
                      name="fName"
                      value={updateFormik.values.fName}
                      onBlur={updateFormik.handleBlur}
                      onChange={updateFormik.handleChange}
                      placeholder="Enter Firm Name"
                    />
                    {updateFormik.touched.fName &&
                      updateFormik.errors.fName && (
                        <Typography sx={{ color: "red" }}>
                          {updateFormik.errors.fName}
                        </Typography>
                      )}
                  </Stack>
                  <Stack flex={1}>
                    <Label>Business Category</Label>
                    <CustomTextField
                      fullWidth
                      size="small"
                      sx={{ marginTop: "8px" }}
                      placeholder="Enter Business Category"
                      name="cat"
                      value={updateFormik.values.cat}
                      onBlur={updateFormik.handleBlur}
                      onChange={updateFormik.handleChange}
                    />
                    {updateFormik.touched.cat && updateFormik.errors.cat && (
                      <Typography sx={{ color: "red" }}>
                        {updateFormik.errors.cat}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Stack flex={1}>
                    <Label>Owner's First Name</Label>
                    <CustomTextField
                      fullWidth
                      size="small"
                      sx={{ marginTop: "8px" }}
                      placeholder="Enter First Name"
                      name="first"
                      value={updateFormik.values.first}
                      onBlur={updateFormik.handleBlur}
                      onChange={updateFormik.handleChange}
                    />
                    {updateFormik.touched.first &&
                      updateFormik.errors.first && (
                        <Typography sx={{ color: "red" }}>
                          {updateFormik.errors.first}
                        </Typography>
                      )}
                  </Stack>
                  <Stack flex={1}>
                    <Label>Owner's Middle Name</Label>
                    <CustomTextField
                      fullWidth
                      size="small"
                      sx={{ marginTop: "8px" }}
                      placeholder="Enter Middle Name"
                      name="middle"
                      value={updateFormik.values.middle}
                      onBlur={updateFormik.handleBlur}
                      onChange={updateFormik.handleChange}
                    />
                    {updateFormik.touched.middle &&
                      updateFormik.errors.middle && (
                        <Typography sx={{ color: "red" }}>
                          {updateFormik.errors.middle}
                        </Typography>
                      )}
                  </Stack>
                  <Stack flex={1}>
                    <Label>Owner's Last Name</Label>
                    <CustomTextField
                      fullWidth
                      size="small"
                      sx={{ marginTop: "8px" }}
                      placeholder="Enter Last Name"
                      name="last"
                      value={updateFormik.values.last}
                      onBlur={updateFormik.handleBlur}
                      onChange={updateFormik.handleChange}
                    />
                    {updateFormik.touched.last && updateFormik.errors.last && (
                      <Typography sx={{ color: "red" }}>
                        {updateFormik.errors.last}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Stack flex={1}>
                    <Label>Mobile Number</Label>
                    <CustomTextField
                      fullWidth
                      type="number"
                      size="small"
                      sx={{ marginTop: "8px" }}
                      placeholder="Enter Mobile Number"
                      name="number"
                      value={updateFormik.values.number}
                      onBlur={updateFormik.handleBlur}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          updateFormik.handleChange(e);
                        }
                      }}
                    />
                    {updateFormik.touched.number &&
                      updateFormik.errors.number && (
                        <Typography sx={{ color: "red" }}>
                          {updateFormik.errors.number}
                        </Typography>
                      )}
                  </Stack>
                  <Stack flex={1}>
                    <Label>Email</Label>
                    <CustomTextField
                      type="email"
                      fullWidth
                      size="small"
                      sx={{ marginTop: "8px" }}
                      placeholder="Enter Email"
                      name="email"
                      value={updateFormik.values.email}
                      onBlur={updateFormik.handleBlur}
                      onChange={updateFormik.handleChange}
                    />
                    {updateFormik.touched.email &&
                      updateFormik.errors.email && (
                        <Typography sx={{ color: "red" }}>
                          {updateFormik.errors.email}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Stack width="100%">
                    <Label>Firm's Address</Label>
                    <CustomTextField
                      fullWidth
                      size="small"
                      sx={{ marginTop: "8px" }}
                      placeholder="Enter Firm's Address"
                      name="address"
                      value={updateFormik.values.address}
                      onBlur={updateFormik.handleBlur}
                      onChange={updateFormik.handleChange}
                    />
                    {updateFormik.touched.address &&
                      updateFormik.errors.address && (
                        <Typography sx={{ color: "red" }}>
                          {updateFormik.errors.address}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Stack flex={1} spacing={1}>
                    <Label>Select State</Label>
                    <CustomSelect
                      displayEmpty
                      value={updateFormik.values.state}
                      MenuProps={MenuProps}
                      name="state"
                      onChange={updateFormik.handleChange}
                      onBlur={updateFormik.handleBlur}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {States.map((state) => (
                        <MenuItem value={state}>{state}</MenuItem>
                      ))}
                    </CustomSelect>
                    {updateFormik.errors.state &&
                      updateFormik.touched.state && (
                        <Typography sx={{ color: "red" }}>
                          {updateFormik.errors.state}
                        </Typography>
                      )}
                  </Stack>
                  <Stack flex={1} spacing={1}>
                    <Label>Select District</Label>
                    <CustomSelect
                      displayEmpty
                      value={updateFormik.values.dist}
                      MenuProps={MenuProps}
                      name="dist"
                      disabled={!updateFormik.values.state}
                      onChange={updateFormik.handleChange}
                      onBlur={updateFormik.handleBlur}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {districts?.length > 1 &&
                        districts?.map((dist) => (
                          <MenuItem value={dist}>{dist}</MenuItem>
                        ))}
                    </CustomSelect>
                    {updateFormik.errors.dist && updateFormik.touched.dist && (
                      <Typography sx={{ color: "red" }}>
                        {updateFormik.errors.dist}
                      </Typography>
                    )}
                  </Stack>
                  <Stack flex={1}>
                    <Label>Enter PIN code</Label>
                    <CustomTextField
                      type="number"
                      fullWidth
                      size="small"
                      sx={{ marginTop: "8px" }}
                      name="pin"
                      value={updateFormik.values.pin}
                      onBlur={updateFormik.handleBlur}
                      onChange={(e) => {
                        if (e.target.value.length <= 6) {
                          updateFormik.handleChange(e);
                        }
                      }}
                      placeholder="Enter PIN code"
                    />
                    {updateFormik.touched.pin && updateFormik.errors.pin && (
                      <Typography sx={{ color: "red" }}>
                        {updateFormik.errors.pin}
                      </Typography>
                    )}
                  </Stack>
                </Stack>

                <Stack direction="row" gap={5}>
                  <Stack>
                    <Label>Profile Pic</Label>
                    <Box>
                      <img
                        src={
                          state?.image?.link
                            ? state?.image?.link
                            : "/image-placeholder.jpeg"
                        }
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                        alt="ekalsutra"
                      />
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        component="label"
                      >
                        <input
                          type="file"
                          accept="image/*,application/pdf"
                          hidden
                          onChange={handleUpload}
                        />
                        Upload
                      </Button>
                    </Box>
                  </Stack>
                  <Stack>
                    <Label>Firm Pic</Label>
                    <Box>
                      <img
                        src={
                          state?.pic?.link
                            ? state?.pic?.link
                            : "/image-placeholder.jpeg"
                        }
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                        alt="ekalsutra"
                      />
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        component="label"
                      >
                        <input
                          type="file"
                          accept="image/*,application/pdf"
                          hidden
                          onChange={handleFirmPicUpload}
                        />
                        Upload
                      </Button>
                    </Box>
                  </Stack>
                </Stack>

                <Stack direction="row" spacing={2}>
                  <Stack flex={1}>
                    <Label>Description</Label>
                    <TextareaAutosize
                      style={{
                        marginTop: "8px",
                        border: "1px solid #cfcfcf",
                        borderRadius: "10px",
                        padding: "10px",
                        resize: "none",
                      }}
                      name="desc"
                      value={updateFormik.values.desc}
                      onBlur={updateFormik.handleBlur}
                      onChange={updateFormik.handleChange}
                      placeholder="Enter Description"
                    />
                    {updateFormik.touched.desc && updateFormik.errors.desc && (
                      <Typography sx={{ color: "red" }}>
                        {updateFormik.errors.desc}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                loading={updateFirmLoading || loading}
                type="submit"
                form="form"
                variant="contained"
                color="secondary"
                size="large"
              >
                SUBMIT
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            sx={{ fontWeight: 500, fontSize: "1.5rem", color: "#0C2F49" }}
          >
            Firm's Profile
          </Typography>
        </Stack>
        <Stack
          maxWidth="60%"
          height="80vh"
          marginLeft="auto"
          marginRight="auto"
          sx={{
            borderRadius: "1rem",
            border: "1px solid #B6C8D6",
          }}
        >
          <Stack position="relative">
            <Stack>
              <img
                src={data?.firmPic ? data?.firmPic : "/banner-placeholder.png"}
                style={{
                  height: 270,
                  objectFit: "cover",
                  borderRadius: "10px 10px 0 0",
                }}
                alt=""
              />
            </Stack>
            <Stack position="absolute" width="100%" bottom="-30px" left="45%">
              <img
                src={
                  data?.profilePic
                    ? data?.profilePic
                    : "/image-placeholder.jpeg"
                }
                width="100px"
                height="100px"
                alt="profile-pic"
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            </Stack>
            <Stack position="absolute" width="100%" bottom="-30px" left="54%">
              {/* <Box sx={{ background: "#08aec4", borderRadius: "50%", padding: "10px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                </svg>
              </Box> */}
              <EditIcon
                sx={{
                  cursor: "pointer",
                  background: "#08aec4",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  padding: "2px",
                  color: "#ffffff",
                }}
                onClick={() => {
                  updateFormik.setValues(() => ({
                    id: data._id,
                    fName: data?.firmName,
                    cat: data?.businessCategory,
                    first: data?.firstName,
                    middle: data?.middleName,
                    last: data?.lastName,
                    number: data?.mobileNumber,
                    email: data?.email,
                    address: data?.firmAddress?.address,
                    state: data?.firmAddress?.state,
                    dist: data?.firmAddress?.dist,
                    pin: data?.firmAddress?.pinCode,
                    profilePic: data?.profilePic,
                    firmPic: data?.firmPic,
                    desc: data?.description,
                  }));
                  setState((prev) => ({
                    ...prev,
                    image: {
                      link: data?.profilePic,
                    },
                    pic: {
                      link: data?.firmPic,
                    },
                  }));
                  setDialogs((prev) => ({ ...prev, update: true }));
                }}
              />
            </Stack>
          </Stack>
          <Stack mt={2}>
            <Typography
              sx={{
                padding: "20px",
                textAlign: "center",
                fontWeight: 500,
                fontSize: "2rem",
                color: "#0C2F49",
              }}
            >
              {data?.firmName || "--"}
            </Typography>
          </Stack>
          <Stack sx={{ padding: "20px" }}>
            <Label>Owner Name</Label>
            <Typography
              sx={{ fontWeight: 700, fontSize: "1.5rem", color: "#0C2F49" }}
            >
              {`${data?.firstName} ${data?.middleName} ${data?.lastName}` ||
                "--"}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Stack flex={1} sx={{ padding: "20px" }}>
              <Label>Mobile Number</Label>
              <Typography
                sx={{ fontWeight: 700, fontSize: "1.5rem", color: "#0C2F49" }}
              >
                {data?.mobileNumber ?? "--"}
              </Typography>
            </Stack>
            <Stack flex={1} sx={{ padding: "20px" }}>
              <Label>Email</Label>
              <Typography
                sx={{ fontWeight: 700, fontSize: "1.5rem", color: "#0C2F49" }}
              >
                {data?.email ?? "--"}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ padding: "20px" }}>
            <Label>Firm's Address</Label>
            <Typography
              sx={{ fontWeight: 700, fontSize: "1.5rem", color: "#0C2F49" }}
            >
              {data?.firmAddress?.address}, {data?.firmAddress?.dist},{" "}
              {data?.firmAddress?.state}, {data?.firmAddress?.pinCode}
            </Typography>
          </Stack>
          <Stack sx={{ padding: "20px" }}>
            <Label>Description</Label>
            <Typography
              sx={{ fontWeight: 700, fontSize: "1.5rem", color: "#0C2F49" }}
            >
              {data?.description ?? "--"}
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default FirmProfile;

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: "1.2rem",
  color: "#0C2F49",
  fontWeight: 500,
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  width: "200px",
  backgroundColor: "#fff",
  color: "#333",
  borderRadius: "7px",
}));
