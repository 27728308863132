import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectSelectedSchool,
  userDetailSelector,
  userNameSelector,
} from "app/modules/schools/selectors";
import { savePaymentForSessionEntity } from "app/services/orgService/school.service";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const useRazorPayPaymentGate = ({ refetch = () => {} }) => {
  const selectedSchool = useSelector(selectSelectedSchool);
  const userDetail = useSelector(userDetailSelector);
  const userName = useSelector(userNameSelector);
  const [paymentDetailSuccess, setPaymentDetailSuccess] = useState({});
  const [paymentDetailFailure, setPaymentDetailFailure] = useState({});

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const openRazorpay = (paymentDetail) => {
    var options = {
      key: paymentDetail?.key,
      order_id: paymentDetail?.order_id,
      amount: paymentDetail?.amount,
      currency: paymentDetail?.currency,
      name: paymentDetail?.companyName,
      description: paymentDetail?.description,
      image: paymentDetail?.companyLogo,
      handler: function (response) {
        setPaymentDetailSuccess(response);
      },
      prefill: {
        name: userName,
        email: userDetail?.email ?? "",
        contact: userDetail?.mobileNumber ?? "",
      },
      notes: {
        address: `${selectedSchool?.schoolAddress?.address} ${selectedSchool?.schoolAddress?.dist} ${selectedSchool?.schoolAddress?.state} ${selectedSchool?.schoolAddress?.pinCode}`,
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      setPaymentDetailFailure(response);
    });
    rzp1.open();
  };

  const { mutate: savePaymentForSessionEntityMutate } = useMutation(
    savePaymentForSessionEntity,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setPaymentDetailSuccess({});
        setPaymentDetailFailure({});
        refetch();
      },
      onError: (error) => {
        toast.error(error.response.data?.message);
      },
    }
  );

  console.log("paymentDetailSuccess", paymentDetailSuccess);
  console.log("paymentDetailFailure", paymentDetailFailure);

  useEffect(() => {
    if (
      paymentDetailSuccess &&
      Object.keys(paymentDetailSuccess).length !== 0
    ) {
      let query = {
        razorpay_payment_id: paymentDetailSuccess?.razorpay_payment_id,
        order_id: paymentDetailSuccess?.razorpay_order_id,
        razorpay_signature: paymentDetailSuccess?.razorpay_signature,
      };
      savePaymentForSessionEntityMutate(query);
    }
    if (
      paymentDetailFailure &&
      Object.keys(paymentDetailFailure).length !== 0
    ) {
      let query = {
        errorDescription: paymentDetailFailure?.description,
        order_id: paymentDetailFailure?.metaData?.order_id,
        payment_id: paymentDetailFailure?.metaData?.payment_id,
        reason: paymentDetailFailure?.reason,
        source: paymentDetailFailure?.source,
        step: paymentDetailFailure?.step,
      };
      // savePaymentForSessionEntityMutate(query);
    }
  }, [paymentDetailSuccess, paymentDetailFailure]);

  return {
    openRazorpay,
  };
};

export default useRazorPayPaymentGate;
