import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
  colors,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { deleteInvoice, getFeeReport } from "app/services/student-management";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

import CommonTable1 from "../../../components/Tables/CommonTable1";
import appRoutes from "app/config/routes";
import { Link } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import DialogHeader from "app/components/common/DialogHeader";
import { userPrimaryRoleSelector } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { IndianCurrency } from "app/utils/helper";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import * as XLSX from "xlsx";
import TableProfilePic from "app/components/common/TableProfilePic";
import Table1Filter from "./components/Table1Filter";
import CustomLoader from "app/components/common/CustomLoader";
import { Helmet } from "react-helmet";

const FeeReportTable = () => {
  const [state, setState] = useState({
    from: new Date(),
    to: new Date(),
    dialog: false,
    deleteData: null,
    feeReportList: [],
    checkExportOptions: false,
    checkExportOptionsPdf: false,
    filterPayload: false,
    totalDiscount: 0,
    totalNetpaid: 0,
  });

  let header = [
    "Session",
    "Class_Stream_Sec",
    "InvoiceNumber",
    "DepositorName",
    "Previous_Balance",
    "TotalAmount",
    "DiscountAmount",
    "DiscountRemark",
    "PaidAmount",
    "BalanceAmount",
    "Student_Name",
    "DOB",
    "Registration_Number",
    "Roll_Number",
    "Mobile_Number",
    "Gender",
    "Father_Name",
    "Transportinfo_using",
    "Transportinfo_routeNumber",
    "Pickup_Point",
    "TransportInfo_vehicleNumber",
    "Status",
    "TaxableAmount",
  ];

  let headerPdf = [
    "Session",
    "Class_Stream_Sec",
    "Student_Name",
    "InvoiceNumber",
    "TotalAmount",
    "DiscountAmount",
    "PaidAmount",
    "BalanceAmount",
    "Status",
    "TaxableAmount",
  ];
  const [selectedOptions, setSelectedOptions] = useState(header);
  const [selectedOptionsForPdf, setSelectedOptionsForPdf] = useState(headerPdf);

  let exportArray = state.feeReportList?.map((item) => {
    return {
      Session: item?.session,
      Class_Stream_Sec: `${item?.studentDetail?.className} - ${item?.studentDetail?.stream} - ${item?.studentDetail?.section}`,
      InvoiceNumber: item?.invoiceNumber,
      DepositorName: item?.depositorName,
      Previous_Balance: item?.studentDetail?.previousBalance,
      TotalAmount: item?.totalAmount,
      DiscountAmount: item?.discountAmount,
      DiscountRemark: item?.discountRemark,
      PaidAmount: item?.paidAmount,
      BalanceAmount: item?.balanceAmount,
      Student_Name: item?.studentDetail?.studentName,
      DOB: item?.studentDetail?.DOB,
      Registration_Number: item?.studentDetail?.registrationNumber,
      Roll_Number: item?.studentDetail?.rollNumber,
      Mobile_Number: item?.studentDetail?.studentMobileNumber,
      Gender: item?.studentDetail?.gender,
      Father_Name: item?.studentDetail?.fatherName,
      Transportinfo_using: item?.studentDetail?.transportInfo?.using,
      Transportinfo_routeNumber:
        item?.studentDetail?.transportInfo?.routeNumber,
      Pickup_Point: item?.studentDetail?.transportInfo?.to,
      TransportInfo_vehicleNumber:
        item?.studentDetail?.transportInfo?.vehicleNumber,
      Status: item?.status,
      TaxableAmount: item?.taxableAmount,
    };
  });

  let exportArrayForPdf = state.feeReportList?.map((item) => {
    return {
      Session: item?.session,
      Class_Stream_Sec: `${item?.studentDetail?.className} - ${item?.studentDetail?.stream} - ${item?.studentDetail?.section}`,
      Student_Name: item?.studentDetail?.studentName,
      InvoiceNumber: item?.invoiceNumber,
      TotalAmount: item?.totalAmount,
      DiscountAmount: item?.discountAmount,
      PaidAmount: item?.paidAmount,
      BalanceAmount: item?.balanceAmount,
      Status: item?.status,
      TaxableAmount: item?.taxableAmount,
    };
  });

  const handleExportPdf = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const rows = [];
    rows.push(selectedOptionsForPdf);
    let totalAmount = 0;
    let totalTaxableAmount = 0;
    let totalDiscountAmount = 0;
    let totalPaidAmount = 0;
    let totalBalanceAmount = 0;
    exportArrayForPdf.forEach((item) => {
      const row = selectedOptionsForPdf.map((option) => item[option]);
      rows.push(row);
      totalAmount += Number(item["TotalAmount"] || 0);
      totalTaxableAmount += Number(item["TaxableAmount"] || 0);
      totalDiscountAmount += Number(item["DiscountAmount"] || 0);
      totalPaidAmount += Number(item["PaidAmount"] || 0);
      totalBalanceAmount += Number(item["BalanceAmount"] || 0);
    });
    rows.push(
      selectedOptionsForPdf.map((option) => {
        switch (option) {
          case "TaxableAmount":
            return totalTaxableAmount;
          case "DiscountAmount":
            return totalDiscountAmount;
          case "PaidAmount":
            return totalPaidAmount;
          case "BalanceAmount":
            return totalBalanceAmount;
          case "TotalAmount":
            return totalAmount;
          default:
            return "";
        }
      })
    );

    const tableColumn = selectedOptionsForPdf.map((col) => ({
      title: col,
      dataKey: col,
    }));
    const tableStyles = {
      headStyles: {
        fillColor: [100, 100, 100],
        textColor: 255,
        fontSize: 10,
        cellPadding: { top: 0, right: 0, bottom: 0, left: 0 },
      },
      bodyStyles: {
        fontSize: 8,
        cellPadding: { top: 0, right: 0, bottom: 0, left: 0 },
      },
      didDrawCell: (data) => {
        if (data.row.index === rows.length - 1) {
          doc.setFont(undefined, "bold");
        } else {
          doc.setFont(undefined, "normal");
        }
      },
    };
    autoTable(doc, { html: "#my-table" });
    autoTable(doc, {
      head: [tableColumn],
      body: rows,
      ...tableStyles,
      bodyStyles: {
        fontSize: 8,
        cellPadding: { top: 0, right: 0, bottom: 0, left: 0 },
      },
      styles: { cellPadding: { top: 0, right: 0, bottom: 0, left: 0 } },
      didParseCell: function (data) {
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
        }
      },
    });
    doc.save("feeReport.pdf");
  };
  const handlegetAllFeeReportMutate = (payload) => {
    getAllFeeReportMutate(payload);
    setState((prev) => ({ ...prev, filterPayload: payload }));
  };
  const primaryRole = useSelector(userPrimaryRoleSelector);
  const {
    isLoading: getAllFeeReportMutateLoading,
    mutate: getAllFeeReportMutate,
  } = useMutation("getAllFeeReportMutate", getFeeReport, {
    onSuccess: ({ data }) => {
      setState((prev) => ({
        ...prev,
        feeReportList: data?.invoiceArray,
        totalNetpaid: data?.totalNetpaid,
        totalDiscount: data?.totalDiscount,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    isLoading: getAllFeeReportLoading,
    isFetching: getAllFeeReportFetching,
    refetch,
  } = useQuery({
    queryKey: ["getAllFeeReport"],
    queryFn: () => {
      if (state.filterPayload) {
        return getFeeReport(state.filterPayload);
      } else {
        return getFeeReport({
          status: "ACTIVE",
        });
      }
    },
    onSuccess: ({ data }) => {
      setState((prev) => ({
        ...prev,
        feeReportList: data?.invoiceArray,
        totalDiscount: data?.totalDiscount,
        totalNetpaid: data?.totalNetpaid,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: false,
  });
  const { isLoading: deleteInvoiceLoading, refetch: deleteInvoiceRefetch } =
    useQuery({
      queryKey: ["deleteInvoice"],
      queryFn: () =>
        deleteInvoice({ invoiceNumber: state.deleteData?.invoiceNumber }),
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setState((prev) => ({ ...prev, deleteData: null }));
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: false,
    });
  const handleDelete = (cell) => {
    const {
      row: { original },
    } = cell;
    setState((prev) => ({ ...prev, deleteData: original }));
  };
  const admincolumns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => (
        <Typography>
          {parseInt(cell?.row?.id) + 1 < 10
            ? `0${parseInt(cell?.row?.id) + 1}`
            : parseInt(cell?.row?.id) + 1}
        </Typography>
      ),
      size: 20,
    },

    {
      header: "Invoice Number",
      accessorKey: "invoiceNumber",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Link
          to={`/${appRoutes.invoice}/${String(original?.invoiceNumber).replace(
            /\//g,
            " "
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "bold" }}
        >
          {original?.invoiceNumber}
        </Link>
      ),
      size: 40,
    },

    {
      header: "Class",
      accessorKey: "className",

      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>
          {original?.studentDetail?.className}-{original?.studentDetail?.stream}
          -{original?.studentDetail?.section}
        </Typography>
      ),
      size: 60,
    },
    {
      header: "Student Name",
      accessorKey: "studentDetail.studentName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Link
            to={`/${appRoutes.student_profile}/${original?.studentDetail?.studentDocId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Stack direction="row" alignItems="center" gap={0.6}>
              <TableProfilePic image={original?.studentDetail?.profilePic} />
              <Typography>{original?.studentDetail?.studentName}</Typography>
            </Stack>
          </Link>
        );
      },
      size: 60,
    },

    {
      header: "Total Amount",
      accessorKey: "totalAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>&#8377;{original?.totalAmount}</Typography>,
      size: 20,
    },
    {
      header: "Discount",
      accessorKey: "discountAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>&#8377;{original?.discountAmount}</Typography>,
      size: 20,
    },
    {
      header: "Balance",
      accessorKey: "balanceAmount",

      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>&#8377;{original?.balanceAmount}</Typography>,
      size: 20,
    },
    {
      header: "Net Paid",
      accessorKey: "paidAmount",

      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>&#8377;{original?.paidAmount}</Typography>,
      size: 20,
    },
    {
      header: "Payment Mode",
      accessorKey: "paymentDetails",

      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>
          {original?.paymentDetails
            ? Object.keys(original?.paymentDetails).map((item) => item)
            : ""}
        </Typography>
      ),
      size: 20,
    },
    {
      header: "Created By",
      accessorKey: "createdBy.personName",

      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>{original?.createdBy?.personName}</Typography>,
      size: 40,
    },
    {
      header: "Actions",
      Cell: ({ cell }) => {
        return (
          <Stack direction="row">
            <Delete
              color="error"
              sx={{ cursor: "pointer" }}
              onClick={() => handleDelete(cell)}
            />
          </Stack>
        );
      },
      size: 20,
    },
  ];

  const staffcolumns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => (
        <Typography>
          {parseInt(cell?.row?.id) + 1 < 10
            ? `0${parseInt(cell?.row?.id) + 1}`
            : parseInt(cell?.row?.id) + 1}
        </Typography>
      ),
      size: 20,
    },

    {
      header: "Invoice Number",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Link
          to={`/${appRoutes.invoice}/${String(original?.invoiceNumber).replace(
            /\//g,
            " "
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "bold" }}
        >
          {original?.invoiceNumber}
        </Link>
      ),
      size: 40,
    },

    {
      header: "Class",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>
          {original?.studentDetail?.className}-{original?.studentDetail?.stream}
          -{original?.studentDetail?.section}
        </Typography>
      ),
      size: 60,
    },

    {
      header: "Student Name",
      accessorKey: "studentDetail.studentName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Link
            to={`/${appRoutes.student_profile}/${original?.studentDetail?.studentDocId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Stack direction="row" alignItems="center" gap={0.6}>
              <TableProfilePic image={original?.studentDetail?.profilePic} />
              <Typography>{original?.studentDetail?.studentName}</Typography>
            </Stack>
          </Link>
        );
      },
      size: 60,
    },

    {
      header: "Total Amount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>&#8377;{original?.totalAmount}</Typography>,
      size: 20,
    },
    {
      header: "Discount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>&#8377;{original?.discountAmount}</Typography>,
      size: 20,
    },
    {
      header: "Balance",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>&#8377;{original?.balanceAmount}</Typography>,
      size: 20,
    },
    {
      header: "Net Paid",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>&#8377;{original?.paidAmount}</Typography>,
      size: 20,
    },
    {
      header: "Payment Mode",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>
          {" "}
          {original?.paymentDetails
            ? Object.keys(original?.paymentDetails).map((item) => item)
            : ""}
        </Typography>
      ),
      size: 20,
    },
    {
      header: "Created By",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>{original?.createdBy?.personName}</Typography>,
      maxSize: 40,
    },
  ];

  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };
  const handleCheckboxChangeForPdf = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptionsForPdf((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptionsForPdf((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };
  const handleExport = () => {
    const filteredExportArray = exportArray.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Fee Report");
    XLSX.writeFile(workbook, "feeReport.xlsx");
  };

  return (
    <div>
      <Helmet>
        <title>Fee | Fee Collection</title>
      </Helmet>
      {/* loader  */}
      <CustomLoader
        show={
          getAllFeeReportLoading ||
          getAllFeeReportMutateLoading ||
          getAllFeeReportFetching
        }
      />
      <Dialog
        open={state.deleteData}
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          handleClose={() =>
            setState((prev) => ({ ...prev, deleteData: null }))
          }
          title="Delete Invoice"
        />
        <Box sx={{ p: 2 }}>
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: "16px",
              fontWeight: 600,
              textAlign: "center",
              pb: 1,
            }}
          >
            Are You Sure Want To Delete.
          </Typography>
          <Typography>
            Invoice Number:-{" "}
            <Link
              to={`/${appRoutes.invoice}/${String(
                state.deleteData?.invoiceNumber
              ).replace(/\//g, " ")}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: "bold" }}
            >
              {state.deleteData?.invoiceNumber}
            </Link>
          </Typography>
          <Typography>
            Student Name:- {state.deleteData?.studentDetail?.studentName}
          </Typography>
          <Typography>
            Class:- {state.deleteData?.studentDetail?.className}-
            {state.deleteData?.studentDetail?.stream}-
            {state.deleteData?.studentDetail?.section}
          </Typography>
          <Typography>
            Total Amount:- {state.deleteData?.totalAmount}
          </Typography>
        </Box>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => setState((prev) => ({ ...prev, deleteData: null }))}
          >
            No
          </Button>
          <LoadingButton
            loading={deleteInvoiceLoading}
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => deleteInvoiceRefetch()}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Dialog for selecting options for export*/}
      <Dialog
        open={state.checkExportOptions}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            checkExportOptions: false,
          }))
        }
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to show in the excel file"
          handleClose={() =>
            setState((prev) => ({
              ...prev,
              checkExportOptions: false,
            }))
          }
        />
        <DialogContent>
          <Box>
            {header.map((option) => (
              <Stack direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChange(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptions(header);
              handleExport();
              setState((prev) => ({
                ...prev,
                checkExportOptions: false,
              }));
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for selecting options for exporting pdf*/}
      <Dialog
        open={state.checkExportOptionsPdf}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            checkExportOptionsPdf: false,
          }))
        }
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to show in the pdf file"
          handleClose={() =>
            setState((prev) => ({
              ...prev,
              checkExportOptionsPdf: false,
            }))
          }
        />
        <DialogContent>
          <Box>
            {headerPdf.map((option, index) => (
              <Stack key={index} direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptionsForPdf.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChangeForPdf(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptionsForPdf(headerPdf);
              handleExportPdf();
              setState((prev) => ({
                ...prev,
                checkExportOptionsPdf: false,
              }));
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Table1Filter
          loading={getAllFeeReportMutateLoading}
          mutate={handlegetAllFeeReportMutate}
        />
        <Stack direction="row" gap={1} alignItems="center">
          <ExportButtonGroup
            handleClickExcel={() =>
              setState((prev) => ({
                ...prev,
                checkExportOptions: true,
              }))
            }
            handleClickPdf={() =>
              setState((prev) => ({
                ...prev,
                checkExportOptionsPdf: true,
              }))
            }
          />
        </Stack>
      </Stack>
      <Stack sx={{ pt: 2 }} direction="row" justifyContent="space-between">
        <FeeBox>
          <Stack direction="row" alignItems={"center"} gap={2}>
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Total Paid
              </Typography>
              <Typography
                style={{ fontWeight: 600, fontSize: "15px", color: "#29DB5B" }}
              >
                {IndianCurrency(state.totalNetpaid)}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Total Discount
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                  color: colors.blue[600],
                }}
              >
                {IndianCurrency(state.totalDiscount)}
              </Typography>
            </Box>
          </Stack>
        </FeeBox>
      </Stack>

      <Box sx={{ mt: 2 }}>
        <CommonTable1
          columns={
            ["ROLE_ADMIN", "ROLE_MANAGING_DIRECTOR"].includes(primaryRole)
              ? admincolumns
              : staffcolumns
          }
          data={state.feeReportList}
          maxHeight="60vh"
        />
      </Box>
    </div>
  );
};

export default FeeReportTable;
const FeeBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.02);
  padding: 10px;
  display: inline-block;
  margin-top: 10px;
`;
