import Fallback from "app/components/Fallback";
import React from "react";

const PaymentOrder = React.lazy(() =>
  import("app/modules/payemnt-gateway/PaymentOrder")
);
const PaymentConfiguration = React.lazy(() =>
  import("app/modules/payemnt-gateway/PaymentConfiguration")
);
const RazorpayCallback = React.lazy(() =>
  import("app/modules/payemnt-gateway/RazorpayCallback")
);

export const PaymentOrderPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <PaymentOrder {...props} />
  </React.Suspense>
);
export const PaymentConfigurationPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <PaymentConfiguration {...props} />
  </React.Suspense>
);
export const RazorpayCallbackPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <RazorpayCallback {...props} />
  </React.Suspense>
);
